import isExternalUrl from "@util/isExternalUrl";
import { getViewAsId, getViewParam } from "@util/viewAs.ts";
import classNames from "classnames";
import { forwardRef } from "react";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import "./ViewAsPreservingLink.scss";

interface ViewAsPreservingLinkProps extends LinkProps {
  underline?: boolean;
  hyperlinkColor?: boolean;
}

export const ViewAsPreservingLink = forwardRef<
  HTMLAnchorElement,
  ViewAsPreservingLinkProps
>(function ViewAsPreservingLink(
  { to, underline = true, hyperlinkColor = true, ...otherProps },
  ref,
) {
  const className = classNames("view-as-preserving-link", {
    "no-underline": !underline,
    "no-hyperlink-color": !hyperlinkColor,
  });

  // If the URL is outside the dashboard, use a regular <a> tag and don't add the view_as_x params
  if (typeof to === "string" && isExternalUrl(to)) {
    return <a ref={ref} className={className} href={to} {...otherProps} />;
  }

  return (
    <Link ref={ref} className={className} to={fixTo(to)} {...otherProps} />
  );
});

export const ViewAsPreservingNavLink = forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>(function ViewAsPreservingNavLink({ to, ...otherProps }, ref) {
  return <NavLink ref={ref} to={fixTo(to)} {...otherProps} />;
});

function fixTo(to: LinkProps["to"]): LinkProps["to"] {
  const viewAsId = getViewAsId();
  if (viewAsId == null) {
    return to;
  }
  if (typeof to === "string") {
    const viewParam = getViewParam(viewAsId);
    return `${to}${to.indexOf("?") <= 0 ? "?" : "&"}${viewParam}`;
  }
  if (typeof to === "function") {
    throw new Error(
      "Functions in `to` prop of ViewAsPreservingLinks are not supported.",
    );
  }
  const { search } = to;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- FIXME
  return { ...to, search: `${search || "?"}&view_as_user=${viewAsId}` };
}
