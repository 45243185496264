import { NetworkId, NetworkInfo } from "@alch/dx-entities";
import {
  UseTRPCQueryResult,
  UseTRPCSuspenseQueryResult,
} from "@trpc/react-query/shared";
import { trpc } from "@util/trpc/trpcClient";
import { useCallback } from "react";
import { z } from "zod";

function useNetworkInfosQuerySelect<T = NetworkInfo[]>(opts?: {
  select?: (networkInfos: NetworkInfo[]) => T;
}) {
  const select = opts?.select;
  return useCallback(
    (networkInfos: NetworkInfo[]) => {
      return select ? select(networkInfos) : networkInfos;
    },
    [select],
  );
}

/**
 * Returns a list of NetworkInfo objects for networks that the user is allowed to see.
 * Deprecated networks are excluded by default.
 */
export function useNetworkInfos<T = NetworkInfo[]>(opts?: {
  select?: (networkInfos: NetworkInfo[]) => T;
}) {
  const select = useNetworkInfosQuerySelect(opts);

  return trpc.config.getNetworkConfig.useQuery(undefined, {
    select,
  }) as UseTRPCQueryResult<
    typeof select extends undefined ? NetworkInfo[] : T,
    Error
  >;
}

/**
 * Returns a list of NetworkInfo objects for networks that the user is allowed to see.
 * Deprecated networks are excluded by default.
 */
export function useSuspenseNetworkInfos<T = NetworkInfo[]>(opts?: {
  select?: (networkInfos: NetworkInfo[]) => T;
}) {
  const select = useNetworkInfosQuerySelect(opts);

  return trpc.config.getNetworkConfig.useSuspenseQuery(undefined, {
    select,
  }) as UseTRPCSuspenseQueryResult<
    typeof select extends undefined ? NetworkInfo[] : T,
    Error
  >;
}

/**
 * Fetch a single NetworkInfo, throwing a query error if its not found
 */
export function useNetworkInfo(network: NetworkId) {
  return useNetworkInfos({
    select: (networkInfos) =>
      selectNetworkFromNetworkInfo(networkInfos, network),
  });
}

export function createNetworkValidator(networkInfos: NetworkInfo[]) {
  return z.string().refine((networkId): networkId is NetworkId => {
    return networkInfos.some((info) => info.id === networkId);
  });
}

function selectNetworkFromNetworkInfo(
  networkInfos: NetworkInfo[],
  networkId: NetworkId,
) {
  const networkInfo = networkInfos.find(
    (networkInfo) => networkInfo.id === networkId,
  );
  if (!networkInfo) {
    // Make useQuery throw an error if the network is not found as if
    // it was a network error
    throw new Error(`Network ${networkId} not found`);
  }
  return networkInfo;
}
