import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import useNavigateSignup from "@features/signup/hooks/useNavigateSignup";
import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import { PaymentElement } from "@stripe/react-stripe-js";
import useStripeInSignup from "../../hooks/useStripeInSignup";
import SignupTemplatePage from "../SignupTemplatePage";

const CURRENT_STEP = SignupStep.Payment;

const StripePaymentPage = () => {
  const { submitMutation, isLoading, loadErrorMessage, handleLoadError } =
    useStripeInSignup();
  const { setFormData } = useSignupFlowFormData();
  const { goToNextStep } = useNavigateSignup({ currentStep: CURRENT_STEP });

  const handlePreviousButtonClick = () => {
    // When going backwards, clear any payment information so it isn't
    // unintentionally submitted if the user switches to the free plan which
    // doesn't show the payment page
    setFormData((existingFormData) => ({
      ...existingFormData,
      [SignupSchemaField.Payment]: {},
    }));
  };

  const handleNextButtonClick = async () => {
    let res;
    try {
      res = await submitMutation.mutateAsync();
    } catch {
      // Mutation errors will be handled by hook
      return;
    }

    setFormData((existingFormData) => ({
      ...existingFormData,
      ...{
        [SignupSchemaField.Payment]: {
          [SignupFormFieldName.StripePaymentMethodId]: res.paymentMethodId,
        },
      },
    }));

    goToNextStep();
  };

  return (
    <SignupTemplatePage
      title="Add payment method"
      subtitle="You're almost ready to unlock the full power of web3."
      currentStep={CURRENT_STEP}
      onPreviousButtonClick={handlePreviousButtonClick}
      onNextButtonClick={handleNextButtonClick}
      formSubmitOverride={false} // disable automatically navigating to the next step
      isSubmitting={submitMutation.isPending}
      isLoading={isLoading}
      errorMessage={loadErrorMessage || submitMutation.error?.message}
    >
      <PaymentElement onLoadError={handleLoadError} />
    </SignupTemplatePage>
  );
};

export default StripePaymentPage;
