import {
  SelectField as BaseSelectField,
  SelectFieldProps as BaseSelectFieldProps,
  SelectFieldValue as BaseSelectFieldValue,
} from "@alch/ui";
import { useCallback, useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

type SelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TItemValue,
  TMultiple extends boolean,
> = Omit<BaseSelectFieldProps<TItemValue, TMultiple>, "value"> & {
  control: Control<TFieldValues>;
  name: TName;
  transformSelect?: (
    value: BaseSelectFieldValue<TItemValue, TMultiple>,
  ) => BaseSelectFieldValue<TItemValue, TMultiple>;
  transformValue?: (
    value: BaseSelectFieldValue<TItemValue, TMultiple>,
  ) => BaseSelectFieldValue<TItemValue, TMultiple>;
};

const SelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TItemValue,
  TMultiple extends boolean = false,
>({
  control,
  name,
  transformSelect,
  transformValue,
  onSelect,
  ...props
}: SelectFieldProps<TFieldValues, TName, TItemValue, TMultiple>) => {
  const {
    field: { value, onChange, onBlur, ref: _ref, ...fieldProps },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const handleSelect = useCallback(
    (value: BaseSelectFieldValue<TItemValue, TMultiple>) => {
      onChange(transformSelect ? transformSelect(value) : value);
      onSelect?.(value);
    },
    [onChange, transformSelect, onSelect],
  );

  const transformedValue = useMemo(() => {
    return transformValue && value !== undefined
      ? transformValue(value)
      : value;
  }, [value, transformValue]);

  return (
    <BaseSelectField
      {...props}
      {...fieldProps}
      value={transformedValue}
      onSelect={handleSelect}
      errorMessage={error?.message}
      onClose={onBlur}
    />
  );
};

export default SelectField;
