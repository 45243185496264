import { ProjectType } from "@alch/dx-entities";
import { File02 } from "@alch/ui/icons/20";
import {
  EventPrefix,
  addRecommendationProperties,
  analyticsEvent,
} from "@util/analytics";
import { hasProperty } from "@util/objects";
import { useEffect } from "react";
import ProductBanner from "./ProductBanner";
import { ProductBannerConfig } from "./banner-configs";

export const projectTypeLabels: Record<ProjectType, string> = {
  [ProjectType.Analytics]: "Analytics",
  [ProjectType.Defi]: "DeFi",
  [ProjectType.Gaming]: "Gaming",
  [ProjectType.Wallet]: "Wallet",
  [ProjectType.Security]: "Security",
  [ProjectType.Social]: "Social",
  [ProjectType.Marketplace]: "Marketplace",
  [ProjectType.InfraAndTooling]: "Infra & Tooling",
  [ProjectType.NFTs]: "NFTs",
  [ProjectType.Identity]: "Identity",
};

const ProductRecommendationBanner = (props: {
  bannerConfig: ProductBannerConfig;
  projectType: string;
  recommendedProduct: string | null;
  onHide: () => void;
}) => {
  const { bannerConfig, projectType, recommendedProduct, onHide } = props;

  useEffect(() => {
    if (!recommendedProduct) return;

    addRecommendationProperties({
      homeRecommendedProduct: recommendedProduct,
    });

    analyticsEvent(`${EventPrefix.HomeRecBanner}: Saw product banner`, {
      product: recommendedProduct,
    });
  }, [recommendedProduct]);

  return (
    <ProductBanner
      title={
        <>
          {hasProperty(projectTypeLabels, projectType)
            ? `${projectTypeLabels[projectType]} teams`
            : "Other teams like you"}{" "}
          use <em className="lg:whitespace-nowrap">{bannerConfig.name}</em>
        </>
      }
      description={bannerConfig.description}
      primaryButtonConfig={{
        url: bannerConfig.actionPath,
        label: bannerConfig.actionLabel,
        onClick: () => {
          analyticsEvent(`${EventPrefix.HomeRecBanner}: Clicked primary CTA`);
        },
      }}
      secondaryButtonConfig={{
        url: bannerConfig.docsUrl,
        label: "Docs",
        icon: <File02 />,
        onClick: () => {
          analyticsEvent(`${EventPrefix.HomeRecBanner}: Clicked Docs link`);
        },
      }}
      onHide={() => {
        onHide();

        analyticsEvent(
          `${EventPrefix.HomeRecBanner}: Closed recommended product banner`,
        );
      }}
    />
  );
};

export default ProductRecommendationBanner;
