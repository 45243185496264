import { ErrorView } from "@alch/ui";
import SignupRoutes from "@features/signup/SignupRoutes";
import useUpdateSentryUser from "@hooks/useUpdateSentryUser";
import useHasCompletedSignup from "@queries/useHasCompletedSignup";
import App from "./App";

/**
 * Children of this component can assume the user has a valid auth token.
 *
 * This component decides whether the user needs to go through the signup flow.
 */
const AuthedRoot = () => {
  const { hasCompletedSignup, isPending, error, refetch } =
    useHasCompletedSignup();

  useUpdateSentryUser();

  if (isPending) {
    return null;
  }

  if (error) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ErrorView
          title="We had a problem.  Please try again."
          message={error.message}
          onRetry={() => {
            void refetch();
          }}
        />
      </div>
    );
  }

  if (!hasCompletedSignup) {
    return <SignupRoutes />;
  }

  return <App />;
};

export default AuthedRoot;
