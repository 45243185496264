import { ErrorView, Loading, Modal } from "@alch/ui";
import useAuthRedirect from "../../hooks/useAuthRedirect.ts";
import useTrackingQueryParams from "../../hooks/useTrackingQueryParams.ts";
import AuthedRoot from "./AuthedRoot.tsx";

Modal.setAppElement("#root");

/**
 * Assigns and verifies the user's auth token.
 */
const Root = () => {
  const { loading, error, refetch } = useAuthRedirect();
  useTrackingQueryParams();

  if (error) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ErrorView
          title="We had a problem.  Please try again."
          message={error.message}
          onRetry={() => {
            void refetch();
          }}
        />
      </div>
    );
  }

  if (loading) {
    return <Loading className="mx-auto my-20" />;
  }

  return <AuthedRoot />;
};

export default Root;
