import { trpc } from "@util/trpc/trpcClient";

const queryOptions = {
  refetchOnWindowFocus: false,
};

const useCurrentUser = () => {
  return trpc.users.getUser.useQuery(undefined, {
    ...queryOptions,
  });
};

export function useSuspenseCurrentUser() {
  return trpc.users.getUser.useSuspenseQuery(undefined, queryOptions);
}

export default useCurrentUser;
