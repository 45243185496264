import { ChevronDown } from "@alch/ui/icons/16";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { Collapsible } from "radix-ui";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { buttonClassName, iconClassName } from "./NavButton";
import { InternalNavLink, NavItemGroup } from "./useNavItems";

interface NavButtonGroupProps {
  group: NavItemGroup;
  children: React.ReactNode;
  activeItem?: InternalNavLink;
}

const NavButtonGroup = ({
  group,
  children,
  activeItem,
}: NavButtonGroupProps) => {
  const [open, setOpen] = useLocalStorage<boolean>(
    `navOpen:${group.title}`,
    false,
    {
      deserializer: (value) => value === "true",
      serializer: (value) => String(value),
    },
  );

  const hasActiveItem = group.items.some((item) => item === activeItem);
  useEffect(() => {
    if (hasActiveItem && !open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore open changes
  }, [hasActiveItem]);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen} asChild>
      <li className="last:mt-auto">
        <Collapsible.Trigger className={clsx(buttonClassName, "group")}>
          {group.icon && <span className={iconClassName}>{group.icon}</span>}

          <span>{group.title}</span>

          <ChevronDown className="ml-auto transition duration-150 group-data-[state=open]:rotate-180" />
        </Collapsible.Trigger>

        <AnimatePresence initial={false}>
          {open && (
            <Collapsible.Content asChild forceMount>
              <motion.ul
                className="ml-2 overflow-hidden border-l border-[#27272A] pl-2"
                initial={{
                  opacity: 0,
                  height: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
                animate={{
                  opacity: 1,
                  height: "auto",
                  marginTop: 4,
                  marginBottom: 8,
                }}
                exit={{
                  opacity: 0,
                  height: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
                transition={{ type: "spring", damping: 80, stiffness: 1000 }}
              >
                {children}
              </motion.ul>
            </Collapsible.Content>
          )}
        </AnimatePresence>
      </li>
    </Collapsible.Root>
  );
};

export default NavButtonGroup;
