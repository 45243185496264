import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { getTeam } from "../../http/endpoints";

export const TEAM_QUERY_KEY = ["team"];

function useQueryOptions() {
  return queryOptions({
    queryKey: TEAM_QUERY_KEY,
    queryFn: async () => {
      return await getTeam();
    },
  });
}

function useTeam() {
  return useQuery(useQueryOptions());
}

export function useSuspenseTeam() {
  return useSuspenseQuery(useQueryOptions());
}

export default useTeam;
