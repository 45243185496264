import { ScrollArea } from "@alch/ui";
import clsx from "clsx";

const scrollShadowClassName = clsx(
  "absolute inset-x-0 z-10 h-2 first:top-0 last:bottom-0",
  "first:bg-linear-to-b last:bg-linear-to-t",
  "pointer-events-none",
);

interface NavScrollAreaProps {
  className?: string;
  shadowClassName: string;
  children: React.ReactNode;
}

const NavScrollArea = ({
  className,
  shadowClassName,
  children,
}: NavScrollAreaProps) => {
  return (
    <ScrollArea
      className={className}
      scrollBarClassName="my-2"
      viewportClassName="grow *:min-h-full"
      theme="light"
      size="sm"
    >
      <div
        aria-hidden="true"
        className={clsx(scrollShadowClassName, shadowClassName)}
      />

      <div className="table-cell h-px px-4 pt-2 pb-3">{children}</div>

      <div
        aria-hidden="true"
        className={clsx(scrollShadowClassName, shadowClassName)}
      />
    </ScrollArea>
  );
};

export default NavScrollArea;
