import { AnnouncementBanner } from "@alch/ui";
import usePlanPreference from "@features/profile/PlanPage/hooks/usePlanPreference";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/root";
import { ViewAsPreservingLink } from "../common/ViewAsPreservingLink";

const BillingFailedBanner = () => {
  const { data: planPreference } = usePlanPreference();

  const openInvoices = useSelector(
    (state: RootState) => state.payments.openInvoices.value,
  );

  // We show billing error notifications to all users.
  // This includes enterprise users who may pay in crypto.
  if (planPreference === undefined || openInvoices === undefined) {
    return null;
  }

  const unpaidInvoices = openInvoices
    .slice()
    .sort(
      (invoice1, invoice2) =>
        invoice1.issue_date_timestamp - invoice2.issue_date_timestamp,
    );

  if (unpaidInvoices.length === 0) {
    return null;
  }

  const oldestOpenInvoice = unpaidInvoices[0];
  const DAY_IN_MS = 24 * 60 * 60 * 1000;
  // const daysRemaining = oldestOpenInvoice.days_until_cutoff;

  const diffMs = Date.now() - oldestOpenInvoice.issue_date_timestamp;

  // If the oldest open invoice is less than 30 days old, don't show the banner.
  if (diffMs < 30 * DAY_IN_MS) {
    return null;
  }

  return (
    <AnnouncementBanner intent="error">
      ⚠️ You have unpaid invoices 😬 - please update{" "}
      <AnnouncementBanner.Link as={ViewAsPreservingLink} to="/settings/billing">
        your billing information and/or pay invoices.
      </AnnouncementBanner.Link>
      {/* {daysRemaining > 0 ? (
        <span>
          within{" "}
          <strong>
            {daysRemaining} {daysRemaining === 1 ? "day" : "days"}
          </strong>{" "}
          for continued service.
        </span>
      ) : (
        <span>
          <strong>immediately</strong> for continued service.
        </span>
      )} */}
    </AnnouncementBanner>
  );
};

export default BillingFailedBanner;
