import { DateTime } from "luxon";

export const SELF_SERVE_LAUNCH_DATE = "2020-07-29";

export const ONE_DAY_MS = 1000 * 60 * 60 * 24;

/**
 * Formats a timestamp into a short, context-aware date string.
 * The format changes based on recency of the timestamp:
 * - Same day: shows time only (8:38 AM)
 * - Within 6 days: shows abbreviated day and time (Thu 8:38 AM)
 * - Within same year: shows abbreviated month, day and time (Feb 16 8:38 AM)
 * - Otherwise: shows full date with time (3/16/2020 8:38 AM)
 *
 * @param timestamp - Unix timestamp in milliseconds
 * @returns A formatted date string
 */
export const getPrettifiedShortDate = (timestamp: number): string => {
  const now = DateTime.now();
  const date = DateTime.fromMillis(timestamp);
  const hourDiff = now.diff(date, "hours").hours;

  // Displays if it is the same day. Ex: 8:38 AM.
  if (now.hasSame(date, "day")) {
    return date.toLocaleString(DateTime.TIME_SIMPLE);
  }

  // Displays if it is within 6 days. Ex: Thu, 8:38 AM.
  if (hourDiff < 24 * 6) {
    return date.toLocaleString({ ...DateTime.TIME_SIMPLE, weekday: "short" });
  }

  // Displays if it is within the same year. Ex: Feb 16, 8:38 AM.
  if (now.year === date.year) {
    return date.toLocaleString({ ...DateTime.DATETIME_MED, year: undefined });
  }

  // Displays otherwise. Ex: 3/16/2020 8:38 AM.
  return date.toLocaleString({ ...DateTime.DATETIME_MED });
};

/**
 * Formats a timestamp into a more detailed, context-aware date string.
 * Similar to getPrettifiedShortDate but with more verbose formatting:
 * - Same day: shows time with seconds (8:38:05 AM)
 * - Within 6 days: shows full day name and time (Thursday, 8:38:05 AM)
 * - Within same year: shows full month, day and time (February 16, 8:38:05 AM)
 * - Otherwise: shows full date with time (3/16/2020, 8:38:05 AM)
 *
 * @param timestamp - Unix timestamp in milliseconds
 * @returns A formatted date string
 */
export const getPrettifiedDate = (timestamp: number): string => {
  const now = DateTime.now();
  const date = DateTime.fromMillis(timestamp);
  const hourDiff = now.diff(date, "hours").hours;

  // Displays if it is the same day. Ex: 8:38:05 AM.
  if (now.hasSame(date, "day")) {
    return date.toLocaleString(DateTime.TIME_WITH_SECONDS);
  }

  // Displays if it is within 6 days. Ex: Thursday, 8:38:05 AM.
  if (hourDiff < 24 * 6) {
    return date.toLocaleString({
      ...DateTime.TIME_WITH_SECONDS,
      weekday: "long",
    });
  }

  // Displays if it is within the same year. Ex: February 16, 8:38:05 AM.
  if (now.year === date.year) {
    return date
      .toLocaleString({
        ...DateTime.DATETIME_MED_WITH_SECONDS,
        month: "long",
        year: undefined,
      })
      .replace(" at", ",");
  }

  // Displays otherwise. Ex: 3/16/2020 8:38:05 AM.
  return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};

/**
 * Calculates the time difference between two timestamps and formats it.
 * If the second timestamp is omitted, the current time is used.
 *
 * @param earlierTS - The earlier timestamp in milliseconds
 * @param laterTS - The later timestamp in milliseconds (defaults to current time)
 * @returns A formatted string of the time difference in HH:MM:SS format
 */
export const getPrettifiedTimeDiff = (earlierTS: number, laterTS?: number) => {
  const earlier = DateTime.fromMillis(earlierTS);
  const later = laterTS ? DateTime.fromMillis(laterTS) : DateTime.now();
  const milliTimeDiff = later.valueOf() - earlier.valueOf();
  return getPendingTimeHandler(milliTimeDiff);
};

/**
 * Formats a duration in milliseconds to HH:MM:SS format
 *
 * @param millis - Duration in milliseconds
 * @returns Formatted string in HH:MM:SS format
 */
const getPendingTimeHandler = (millis: number) => {
  const seconds = Math.floor((millis / 1000) % 60);
  const minutes = Math.floor((millis / 1000 / 60) % 60);
  const hours = Math.floor(millis / (1000 * 60 * 60));
  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;
};

/**
 * Pads a number with leading zeros to ensure it has the specified number of digits
 *
 * @param num - The number to pad
 * @param places - The desired number of digits
 * @returns A string of the number with leading zeros if needed
 */
const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");
