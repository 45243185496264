import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, ReactElement, useCallback, useState } from "react";

export interface BlurbProps {
  className?: string;
  blurbs: BlurbItem[];
  blurbGradient: string;
}

export interface BlurbItem {
  title: string;
  subtitle: string;
  mediaUrl: string;
}

const AnimatedOnboarding = memo(function AnimatedOnboarding({
  className,
  blurbs,
  blurbGradient,
}: BlurbProps): ReactElement {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedBlurb = blurbs[selectedIndex];

  const advanceToNext = () =>
    setSelectedIndex((curIndex) => (curIndex + 1) % blurbs.length);

  return (
    <div className={clsx("flex w-full flex-row gap-6", className)}>
      <div className="hidden flex-[1_0_0] flex-col lg:flex">
        {blurbs.map((blurb, i) => (
          <BlurbSelector
            key={i}
            {...blurb}
            isSelected={i === selectedIndex}
            index={i}
            setSelectedIndex={setSelectedIndex}
            blurbGradient={blurbGradient}
          />
        ))}
      </div>
      <div className="flex flex-[1_0_0] flex-col gap-6">
        <motion.div
          key={selectedIndex}
          className="aspect-square overflow-hidden rounded-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <video
            autoPlay
            muted
            playsInline
            onEnded={advanceToNext}
            style={{ width: 500 }}
          >
            <source src={selectedBlurb.mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </motion.div>
        <div className="block lg:hidden">
          <h3 className="text-xl font-semibold text-grayscale-950">
            {selectedBlurb.title}
          </h3>
          <p className="text-paragraph-200-regular text-grayscale-600">
            {selectedBlurb.subtitle}
          </p>
        </div>
      </div>
    </div>
  );
});

interface BlurbSelectorProps extends BlurbItem {
  isSelected: boolean;
  index: number;
  setSelectedIndex(index: number): void;
  blurbGradient: string;
}

const BlurbSelector = memo(function BlurbSelector({
  title,
  subtitle,
  isSelected,
  index,
  setSelectedIndex,
  blurbGradient,
}: BlurbSelectorProps): ReactElement {
  const handleClick = useCallback(
    () => setSelectedIndex(index),
    [index, setSelectedIndex],
  );

  return (
    <button
      className={clsx(
        "border-l-[3px] border-grayscale-200 py-5 pr-4 pl-6 text-left transition-colors duration-200 hover:bg-grayscale-50",
        {
          [blurbGradient]: isSelected,
        },
      )}
      onClick={handleClick}
    >
      <p
        className={clsx(
          "text-paragraph-300-medium text-grayscale-950 transition-opacity duration-200",
          { "opacity-70": !isSelected },
        )}
      >
        {title}
      </p>
      <p
        className={clsx(
          "text-paragraph-200-regular text-grayscale-600",
          !isSelected && "opacity-70",
        )}
      >
        {subtitle}
      </p>
    </button>
  );
});

export default AnimatedOnboarding;
