import { NetworkInfo, Product, TeamPlanTier } from "@alch/dx-entities";
import { GET_ALL_GAS_MANAGER_POLICIES as GET_ALL_GAS_POLICIES } from "@features/accounts/queries";
import useActivePlan from "@features/profile/PlanPage/hooks/useActivePlan";
import { getGasPolicies } from "@http/endpoints";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { Policy } from "./gasPolicyTypes";

interface GasPoliciesData {
  loading: boolean;
  supportedNetworkInfos: NetworkInfo[];
  allNetworkInfos: NetworkInfo[];
  teamTier: TeamPlanTier;
}

type GasPoliciesContextData = Omit<GasPoliciesData, "loading">;

export function gasPoliciesFlagsFromActivePlan(
  planTier: TeamPlanTier | undefined,
  isLoading: boolean,
  networkInfos: NetworkInfo[],
): GasPoliciesData {
  const teamTier = planTier ?? TeamPlanTier.FREE;

  const supportedNetworkInfos = networkInfos.filter((networkInfo) => {
    if (!networkInfo.supportedProducts.includes(Product.GasManagerApi)) {
      return false;
    }
    if (teamTier === TeamPlanTier.FREE) {
      return networkInfo.isTestNet;
    }
    return true;
  });

  return {
    loading: isLoading,
    supportedNetworkInfos,
    allNetworkInfos: networkInfos,
    teamTier,
  };
}

export function useGasPoliciesFlags(networkInfos: NetworkInfo[]) {
  const activePlan = useActivePlan();
  return gasPoliciesFlagsFromActivePlan(
    activePlan.data?.tier,
    activePlan.isPending,
    networkInfos,
  );
}

export const GasPoliciesContext = createContext<GasPoliciesContextData>({
  supportedNetworkInfos: [],
  allNetworkInfos: [],
  teamTier: TeamPlanTier.FREE,
});

/**
 * In order to use this, make sure your component is wrapped in GasManagerPageWrapper
 */
export function useGasPoliciesContext(): GasPoliciesContextData {
  const data = useContext(GasPoliciesContext);
  if (data === undefined) {
    throw new Error(
      "Gas Policies wasn't initialized, make sure you wrap the component inside <GasManagerPageWrapper />",
    );
  }
  return data;
}

export function useAllGasManagerPolicies(): UseQueryResult<Policy[]> {
  return useQuery({
    queryFn: getAllGasPolicies,
    queryKey: [GET_ALL_GAS_POLICIES],
  });
}

// This is enforced by the backend.
const MAX_GAS_POLICY_PAGE_SIZE = 50;

async function getAllGasPolicies(): Promise<Policy[]> {
  const out: Policy[] = [];
  let after: string | null = null;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await getGasPolicies(
      MAX_GAS_POLICY_PAGE_SIZE,
      null,
      after,
    );
    after = response.after;
    out.push(...response.policies);
    if (
      response.policies.length < MAX_GAS_POLICY_PAGE_SIZE ||
      !after ||
      response.policies.length === 0
    ) {
      return out;
    }
  }
}
