import { IconButton } from "@alch/ui";
import { AlchemyWhite } from "@alch/ui/assets/logos";
import { X01 } from "@alch/ui/icons/24";
import { ViewAsPreservingLink } from "../../common/ViewAsPreservingLink";

interface NavHeaderProps {
  onHideOverlay?: () => void;
  onLogoClick?: () => void;
}

const NavHeader = ({ onHideOverlay, onLogoClick }: NavHeaderProps) => {
  return (
    <header className="relative z-20 flex items-center justify-between gap-4 p-4">
      <IconButton
        background="dark"
        rounded="slight"
        as={ViewAsPreservingLink}
        to="/"
        onClick={onLogoClick}
        aria-label="Alchemy Logo"
      >
        <img
          src={AlchemyWhite}
          alt="Alchemy Logo"
          style={{ width: 104, height: 22 }}
          className="block"
        />
      </IconButton>

      {onHideOverlay ? (
        <IconButton
          className="text-invert"
          padding="lg"
          background="dark"
          onClick={onHideOverlay}
        >
          <X01 className="size-5" />
        </IconButton>
      ) : null}
    </header>
  );
};

export default NavHeader;
