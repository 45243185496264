import SimpleHashBanner from "@features/banners/SimpleHashBanner";
import BillingFailedBanner from "./BillingFailedBanner";
import TrialAnnouncementBanner from "./TrialAnnouncementBanner";
import ViewingAsBanner from "./ViewingAsBanner";

const Banners = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <ViewingAsBanner />
      <BillingFailedBanner />
      <TrialAnnouncementBanner />
      <SimpleHashBanner />
    </div>
  );
};

export default Banners;
