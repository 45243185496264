import useSignupCompleteRedirect from "@features/signup/hooks/useSignupCompleteRedirect";
import useSearchParam, { SearchParam } from "../../hooks/useSearchParam";
import useCurrentUser from "./useCurrentUser";

/**
 * Checks if the user has gone through the signup flow
 */
const useHasCompletedSignup = () => {
  const { data: currentUser, isPending, error, refetch } = useCurrentUser();
  const testSignup = useSearchParam(SearchParam.TestSignup);

  // If a user has a teamId, they have completed signup
  const hasCompletedSignup = testSignup
    ? false
    : !currentUser
      ? undefined // Undefined if loading
      : !!currentUser.teamId; // Otherwise, they've completed if there's a team id

  // Handle the ?redirectUrl param if it exists
  useSignupCompleteRedirect(hasCompletedSignup);

  return { isPending, hasCompletedSignup, error, refetch };
};

export default useHasCompletedSignup;
