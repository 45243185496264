import { User } from "@alch/dx-entities";
import { DateTime } from "luxon";
import { OrderedIdMap } from "./orderedIdMap";

export function getInitials(user: User): string {
  return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase();
}

export function getFullName(user: Partial<User>, orElse: string): string {
  return user.firstName || user.lastName
    ? `${user.firstName ?? ""} ${user.lastName ?? ""}`
    : orElse;
}

export function getShortName(
  userId: number,
  users: OrderedIdMap<number, User>,
): string | undefined {
  const user = users.byId[userId];
  if (!user) {
    return undefined;
  }
  const { id, firstName, lastName } = user;
  if (!firstName && !lastName) {
    return undefined;
  }
  const isUniqueFirstName = !Object.values(users.byId).find(
    (u) => u.firstName === firstName && u.id !== id,
  );
  return isUniqueFirstName ? firstName : `${firstName} ${lastName[0]}.`;
}

export function dashify(input: string): string {
  return input.trim().replace(/\s+/g, "-").toLowerCase();
}

export function formatDateRange(
  defaultText: string,
  startDate: Date | undefined,
  endDate: Date | undefined,
): string {
  const formatDate = (date: Date) =>
    DateTime.fromJSDate(date).toFormat("LLL d, h:mm:ssa");
  if (startDate != null && endDate != null) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  if (startDate != null) {
    return `${formatDate(startDate)} - now`;
  }
  if (endDate != null) {
    return `< ${formatDate(endDate)}`;
  }
  return defaultText;
}

export function formatNumberRange(
  defaultText: string,
  min: number | undefined,
  max: number | undefined,
  { unit = "", prefix = "" } = {},
): string {
  if (min == null && max == null) {
    return defaultText;
  }
  const rangeText = (() => {
    if (min != null && max != null) {
      return min === max ? `${min}${unit}` : `${min}${unit} - ${max}${unit}`;
    }
    if (min != null) {
      return `≥ ${min}${unit}`;
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- FIXME
    return `≤ ${max}${unit}`;
  })();
  return `${prefix}${rangeText}`;
}

export function capitalize(s: string): string {
  return s ? `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}` : s;
}

export function capitalizeWords(s: string): string {
  return s.split(" ").map(capitalize).join(" ");
}

/**
 * Returns a hash code from a string
 * @param  str The string to hash.
 * @return A postive 32bit integer
 * @see https://stackoverflow.com/a/8831937
 **/
export function hashString(str: string): number {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
}
