import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  InternalNavLink,
  NavItem,
  isGroupItem,
  isInternalLink,
} from "./useNavItems";

const useNavActiveItem = (items: NavItem[]) => {
  const { pathname } = useLocation();

  const matchesPath = useCallback(
    (item: NavItem): item is InternalNavLink => {
      if (!isInternalLink(item)) {
        return false;
      }

      if (item.exact) {
        return item.to === pathname;
      }

      return pathname.startsWith(item.to);
    },
    [pathname],
  );

  return useMemo(() => {
    const flatItems = items.flatMap((item) =>
      isGroupItem(item) ? item.items : [item],
    );
    return flatItems.find(matchesPath);
  }, [items, matchesPath]);
};

export default useNavActiveItem;
