import { LowOpacityButton } from "@alch/ui";
import { MessageChat01 } from "@alch/ui/icons/20";
import { useState } from "react";
import FeedbackModal from "./FeedbackModal";

interface NavFooterProps {
  onHideOverlay?: () => void;
}

const NavFooter = ({ onHideOverlay }: NavFooterProps) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <footer className="relative z-20 flex flex-col gap-2 p-4 pt-0">
      <LowOpacityButton
        icon={<MessageChat01 />}
        className="light flex w-full justify-center overflow-hidden rounded-sm whitespace-nowrap"
        onClick={() => setIsFeedbackModalOpen(true)}
      >
        Share feedback
      </LowOpacityButton>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => {
          setIsFeedbackModalOpen(false);
          onHideOverlay?.();
        }}
      />
    </footer>
  );
};

export default NavFooter;
