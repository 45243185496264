import { ChainInfo } from "@alch/dx-entities";
import { Button, IconButton } from "@alch/ui";
import { ChevronRight } from "@alch/ui/icons/20";
import { X01 } from "@alch/ui/icons/24";
import ChainEmblem from "@components/ui/icons/ChainEmblem";
import { ViewAsPreservingLink } from "@features/common/ViewAsPreservingLink";
import isExternalUrl from "@util/isExternalUrl";
import clsx from "clsx";

const DEFAULT_BACKGROUND_COLOR = "#3943FA";

interface ProductBannerButtonConfig {
  url: string;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface ProductBannerImageProps {
  imageUrl?: string;
  chainInfo?: ChainInfo;
}

const ProductBannerImage = ({
  imageUrl,
  chainInfo,
}: ProductBannerImageProps) => {
  if (imageUrl) {
    return (
      <div className="relative flex grow justify-end max-sm:hidden">
        <div className="absolute inset-y-10 lg:inset-y-8 xl:px-15">
          <img
            className="h-full object-cover object-left"
            src={imageUrl}
            alt=""
          />
        </div>
      </div>
    );
  }

  if (!chainInfo) return null;

  return (
    <div className="relative flex items-center self-center pr-9 max-sm:hidden lg:pr-17">
      <div className="rounded-full border-[12px] border-white/10 bg-white/30 bg-clip-padding p-4 backdrop-blur-[50px]">
        <ChainEmblem
          chainInfo={chainInfo}
          className="size-24 rounded-full bg-white"
        />
      </div>
    </div>
  );
};

interface ProductBannerProps {
  title: React.ReactNode;
  description: React.ReactNode;
  primaryButtonConfig?: ProductBannerButtonConfig;
  secondaryButtonConfig?: ProductBannerButtonConfig;
  imageUrl?: string;
  backgroundColor?: string;
  chainInfo?: ChainInfo;
  onHide: () => void;
}

const ProductBanner = ({
  title,
  description,
  primaryButtonConfig,
  secondaryButtonConfig,
  imageUrl,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  chainInfo,
  onHide,
}: ProductBannerProps) => {
  return (
    <div
      className={clsx(
        "relative isolate flex min-h-60 justify-between overflow-hidden rounded-xl",
        "from-white/10 from-55% to-55% lg:bg-linear-40",
      )}
      style={{ backgroundColor }}
    >
      <div className="absolute top-1/2 left-2/3 -z-1 h-48 w-96 rounded-t-full bg-white/10 lg:hidden" />

      <div className="relative flex max-w-[552px] flex-col gap-6 p-8 sm:w-3/4 lg:w-2/3 xl:w-1/2">
        <div className="flex flex-col gap-2">
          <h2 className="text-heading-h2 text-balance text-white">{title}</h2>

          <p className="text-paragraph-300-regular text-balance text-white">
            {description}
          </p>
        </div>

        {(primaryButtonConfig || secondaryButtonConfig) && (
          <div className="mt-auto flex gap-2">
            {primaryButtonConfig && (
              <Button
                size="md"
                intent="secondary"
                trailingIcon={<ChevronRight />}
                as={ViewAsPreservingLink}
                to={primaryButtonConfig.url}
                target={
                  isExternalUrl(primaryButtonConfig.url) ? "_blank" : undefined
                }
                icon={primaryButtonConfig.icon}
                onClick={primaryButtonConfig.onClick}
              >
                {primaryButtonConfig.label}
              </Button>
            )}

            {secondaryButtonConfig && (
              <Button
                size="md"
                intent="tertiary"
                as={ViewAsPreservingLink}
                to={secondaryButtonConfig.url}
                target={
                  isExternalUrl(secondaryButtonConfig.url)
                    ? "_blank"
                    : undefined
                }
                icon={secondaryButtonConfig.icon}
                onClick={secondaryButtonConfig.onClick}
              >
                {secondaryButtonConfig.label}
              </Button>
            )}
          </div>
        )}
      </div>

      <ProductBannerImage imageUrl={imageUrl} chainInfo={chainInfo} />

      <IconButton
        className="absolute top-3 right-3 text-icon-invert"
        background="dark"
        onClick={onHide}
      >
        <X01 />
      </IconButton>
    </div>
  );
};

export default ProductBanner;
