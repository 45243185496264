import { NetworkInfo } from "@alch/dx-entities";
import urlJoin from "url-join";

enum Url {
  ChatWeb3FeedbackForm = "https://alchemyapi.typeform.com/to/fiwDYr6S",
  CustomRpcGuide = "https://www.alchemy.com/blog/supercharge-metamask",
  DiscordSupport = "https://discord.gg/alchemyplatform", // Discord invite behavior decision here https://alchemyinsights.slack.com/archives/C03JX8DJP40/p1704998235494829?thread_ts=1704991742.866929&cid=C03JX8DJP40
  GetAmplified = "https://alchemyapi.typeform.com/to/Yhl13CyU",
  Spearmint = "https://spearmint.xyz/",
  University = "https://alchemy.com/university",
  UniversitySolidityCourse = "https://www.alchemy.com/university/courses/solidity",
  YouTubeChannel = "https://www.youtube.com/c/alchemyplatform",
  AlchemyPricing = "https://www.alchemy.com/pricing",
  Satsuma = "https://alchemy.com/subgraphs-signup",
  AccountKit = "https://accountkit.alchemy.com",
  EveryoneOnchainFund = "https://www.alchemy.com/everyone-onchain-fund?utm_source=account_kit&utm_medium=dashboard&utm_campaign=everyone_onchain_fund",
  Home = "https://alchemy.com",
  ContactSales = "https://www.alchemy.com/contact-sales",
  ContactSalesRollups = "https://www.alchemy.com/contact-sales-rollups",
  Terms = "https://www.alchemy.com/policies/terms",
  AccountsSupplementTerms = "https://www.alchemy.com/terms-conditions/supplemental-terms",
  AccountsUserTerms = "https://www.alchemy.com/terms-conditions/end-user-terms",
  ModularAccount = "https://accountkit.alchemy.com/smart-accounts/modular-account/getting-started.html",
  EmbeddedAccounts = "https://accountkit.alchemy.com/getting-started/introduction.html",
  EmbeddedAccountsUIComponentDemo = "https://demo.alchemy.com",
  EmbeddedAccountsArbitrumGasSponsorshipSignUp = "https://alchemyapi.typeform.com/to/DI2scQDU?utm_source=announcement&utm_medium=account_kit_dashboard&utm_campaign=account_kit_arb_gas",
  EmbeddedAccountsIntegrationSupport = "https://alchemy.chilipiper.com/book/inbound-support-router?ws_support__c=/wallet-services-support",
  AlchemySigner = "https://accountkit.alchemy.com/signers/alchemy-signer/introduction.html",
  UserOperations = "https://accountkit.alchemy.com/packages/aa-alchemy/middleware/alchemyUserOperationSimulator.html",
  PipelinesEarlyAccess = "https://alchemyapi.typeform.com/to/p0WvSU9q",
  PipelineLottieSvg = "https://www.datocms-assets.com/105223/1714165189-pipelines-flow-2.json",
  RaasSignup = "https://alchemyapi.typeform.com/to/l0LWIgov",
  Faucets = "https://faucets.alchemy.com",
  StatusPage = "https://status.alchemy.com",
}

enum AlchemyDocsUrl {
  AccountAbstractionSDK = "https://docs.alchemy.com/reference/account-abstraction-sdk",
  AlchemySDK = "https://docs.alchemy.com/reference/alchemy-sdk-quickstart",
  Alerts = "https://docs.alchemy.com/docs/dashboard-alerts",
  ArbitrumNovaQuickStart = "https://docs.alchemy.com/reference/arbitrum-nova-api-quickstart",
  ArchiveData = "https://docs.alchemy.com/docs/what-is-archive-data-on-ethereum",
  AvalancheQuickStart = "https://docs.alchemy.com/reference/avalanche-api-quickstart",
  BaseApi = "https://docs.alchemy.com/reference/base-api-quickstart",
  BlockTimestampApi = "https://docs.alchemy.com/reference/blocks-by-timestamp",
  BerachainQuickStart = "https://docs.alchemy.com/reference/berachain-api-quickstart",
  BNBSmartChainQuickStart = "https://docs.alchemy.com/reference/bnb-smart-chain-api-quickstart",
  BundlerApi = "https://docs.alchemy.com/reference/bundler-api-quickstart",
  ChainApi = "https://docs.alchemy.com/reference/chain-apis-overview",
  ChatWeb3 = "https://docs.alchemy.com/docs/chatweb3#introduction",
  ComputeUnits = "https://docs.alchemy.com/reference/compute-units",
  CreateWeb3Dapp = "https://docs.alchemy.com/docs/create-web3-dapp-quickstart",
  CrossFiQuickStart = "https://docs.alchemy.com/reference/crossfi-api-quickstart",
  CUCosts = "https://docs.alchemy.com/reference/compute-unit-costs",
  CustomWebhooksQuickstart = "https://docs.alchemy.com/reference/custom-webhooks-quickstart",
  DataPlatform = "https://alchemotion.notion.site/Alchemy-Pipelines-Docs-v0-external-4f6f3d4d0dbd45e2b6fe0897c5d6102c", // Temporary link
  DebugApi = "https://docs.alchemy.com/reference/debug-api-quickstart",
  ErrorReference = "https://docs.alchemy.com/reference/error-reference",
  ErrorCodes = `${AlchemyDocsUrl.ErrorReference}#standard-json-rpc-errors`,
  EthCall = "https://docs.alchemy.com/reference/eth-call",
  EthereumApiQuickstart = "https://docs.alchemy.com/reference/ethereum-api-quickstart",
  FantomQuickStart = "https://docs.alchemy.com/reference/fantom-opera-api-quickstart",
  FeatureSupport = "https://docs.alchemy.com/reference/feature-support-by-chain",
  FlowQuickStart = "https://docs.alchemy.com/reference/flow-api-quickstart",
  GasManager = "https://docs.alchemy.com/docs/gas-manager-services",
  GasManagerAdminApi = "https://docs.alchemy.com/reference/gas-manager-admin-api-quickstart",
  GasManagerCoverageApi = "https://docs.alchemy.com/reference/gas-manager-coverage-api-quickstart",
  GasOptimizedTransactions = "https://docs.alchemy.com/reference/gas-optimized-transactions",
  GnosisQuickStart = "https://docs.alchemy.com/reference/gnosis-api-quickstart",
  Home = "https://docs.alchemy.com/",
  LineaQuickStart = "https://docs.alchemy.com/reference/linea-chain-api-quickstart",
  LogEvents = "https://docs.alchemy.com/docs/how-to-get-on-chain-events",
  MantleQuickStart = "https://docs.alchemy.com/reference/mantle-chain-api-quickstart",
  MetisQuickStart = "https://docs.alchemy.com/reference/metis-chain-api-quickstart",
  MultiChainApps = "https://docs.alchemy.com/docs/multichain-apps",
  NftApi = "https://docs.alchemy.com/reference/nft-api-quickstart",
  OpBNBQuickStart = "https://docs.alchemy.com/reference/opbnb-chain-api-quickstart",
  PolygonZkEVMQuickstart = "https://docs.alchemy.com/reference/polygon-zkevm-api-quickstart",
  PricesApi = "https://docs.alchemy.com/reference/prices-api-quickstart",
  PricingAutoscale = "https://docs.alchemy.com/reference/pricing-plans#auto-scale-compute-growth--scale-tier-only",
  RaasDocs = "https://docs.alchemy.com/reference/rollups-quickstart",
  ReinforcedTransactions = "https://docs.alchemy.com/reference/reinforced-transactions",
  ScrollQuickStart = "https://docs.alchemy.com/reference/scroll-api-quickstart",
  SmartWebSockets = "https://docs.alchemy.com/reference/sdk-websockets-endpoints",
  Subgraphs = "https://docs.alchemy.com/reference/subgraphs-quickstart",
  SubscriptionApi = "https://docs.alchemy.com/reference/subscription-api",
  Throughput = "https://docs.alchemy.com/reference/throughput",
  TokenApi = "https://docs.alchemy.com/reference/token-api-quickstart",
  TraceApi = "https://docs.alchemy.com/reference/trace-api-quickstart",
  TransactApi = "https://docs.alchemy.com/reference/transact-api-quickstart",
  TransactionReceiptsApi = "https://docs.alchemy.com/reference/alchemy-gettransactionreceipts",
  TransactionSimulationApi = "https://docs.alchemy.com/reference/simulation",
  TransfersApi = "https://docs.alchemy.com/reference/transfers-api-quickstart",
  UserOperationsApi = "https://accountkit.alchemy.com/packages/aa-alchemy/middleware/alchemyUserOperationSimulator.html",
  WebhooksApi = "https://docs.alchemy.com/reference/notify-api-quickstart",
  ZetaChainQuickStart = "https://docs.alchemy.com/reference/zetachain-api-quickstart ",
  ZKsyncQuickStart = "https://docs.alchemy.com/reference/zksync-api-quickstart",
  SoneiumQuickStart = "https://docs.alchemy.com/reference/soneium-api-quickstart",
  WorldChainQuickStart = "https://docs.alchemy.com/reference/worldchain-api-quickstart",
}

enum MailTo {
  SubgraphSales = "mailto:subgraph-sales@alchemy.com",
}

// Removes any urls from sentence, helpful for printing errors and not exposing internal urls externally
// Yes - its chatgpt generated
function stripUrls(sentence: string): string {
  const words = sentence.split(/\s+/); // Split the sentence into words
  function isUrl(word: string): boolean {
    // This regex pattern checks for common URL structures
    const urlRegex = /^(?:(?:https?|ftp):\/\/)?[\w/:%#$&?()~.=+-]+$/i;
    return urlRegex.test(word);
  }
  // Filter out words that look like URLs
  const filteredWords = words.filter((word) => !isUrl(word));
  // Join the filtered words back into a sentence
  return filteredWords.join(" ");
}

const buildExplorerUrl = (
  networkInfo: Pick<NetworkInfo, "explorerUrl">,
  path: "tx" | "address" | "block",
  hash: string,
) => {
  const [baseUrl, qs] = networkInfo.explorerUrl.split("?");

  if (!baseUrl) return "";

  return urlJoin(baseUrl, path, hash, qs ? `?${qs}` : "");
};

/**
 * Copies over the given params from the current URL to the target URL path, if they exist.
 * @param currentUrlOrPathStr location.href
 * @param targetPathStr a path like /app/123
 * @param queryParamKeys the keys to copy over like ['view_as_user']
 */
function preserveQueryParamsInPath(
  currentUrlOrPathStr: string,
  targetPathStr: string,
  queryParamKeys: string[],
) {
  const url = new URL(targetPathStr, "http://thisisignored.com");
  const windowUrl = new URL(currentUrlOrPathStr, "http://thisisignored.com");

  const queryParams = new URLSearchParams(windowUrl.search);
  queryParams.forEach((value, key) => {
    if (queryParamKeys.includes(key)) {
      url.searchParams.set(key, value);
    }
  });

  return `${url.pathname}${url.search}${url.hash}`;
}

export {
  AlchemyDocsUrl,
  buildExplorerUrl,
  MailTo,
  preserveQueryParamsInPath,
  stripUrls,
  Url,
};
