import { TeamPlan } from "@features/team/types";
import { useQuery } from "@tanstack/react-query";
import { Method, callEndpoint } from "../../../../http/axiosConfig";
import usePlanOverride from "./usePlanOverride";

export const PLAN_PREFERENCE_QUERY_KEY = ["plan_preference"];

export default function usePlanPreference() {
  const {
    tier: tierOverride,
    term: termOverride,
    usageCapType: usageCapTypeOverride,
  } = usePlanOverride();

  return useQuery<TeamPlan, Error>({
    queryKey: PLAN_PREFERENCE_QUERY_KEY,
    queryFn: () => {
      return callEndpoint<TeamPlan>(Method.GET, "/api/team-plan").then(
        (res) => ({
          ...res,
          tier: tierOverride !== undefined ? tierOverride : res.tier,
          plan_term: termOverride !== undefined ? termOverride : res.plan_term,
          usage_cap_type:
            usageCapTypeOverride !== undefined
              ? usageCapTypeOverride
              : res.usage_cap_type,
        }),
      );
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
