import useCurrentUser from "@queries/useCurrentUser";
import * as Sentry from "@sentry/react";
import { getViewAsId } from "@util/viewAs";
import { useEffect } from "react";

/**
 * Sends the current user metadata to Sentry.
 */
const useUpdateSentryUser = () => {
  const { data: user, isPending: isUserLoading } = useCurrentUser();

  useEffect(() => {
    if (!isUserLoading) {
      if (!user || getViewAsId() != null) {
        Sentry.getCurrentScope().clear();
      } else {
        Sentry.getCurrentScope().setUser({
          id: user.id,
          email: user.email,
        });
      }
    }
  }, [user, isUserLoading]);
};

export default useUpdateSentryUser;
