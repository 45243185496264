import { AnnouncementBanner } from "@alch/ui";
import { OneTimeEvent, useOneTimeEventStorage } from "@util/localStorage.ts";
import { ReactNode } from "react";

interface NewFeatureAnnouncementProps {
  children: ReactNode;
  oneTimeEvent: OneTimeEvent;
}

const NewFeatureAnnouncementBanner = ({
  children,
  oneTimeEvent,
}: NewFeatureAnnouncementProps) => {
  const [isEventSet, setEvent] = useOneTimeEventStorage(oneTimeEvent);

  if (isEventSet) {
    return null;
  }

  return (
    <AnnouncementBanner onDismiss={setEvent} data-test-id="new-feature-banner">
      {children}
    </AnnouncementBanner>
  );
};

export default NewFeatureAnnouncementBanner;
