import { analyticsEvent, EventPrefix } from "@util/analytics";
import clsx from "clsx";
import { HTMLMotionProps, motion } from "framer-motion";
import { useCallback } from "react";
import NavButton from "./NavButton";
import NavButtonGroup from "./NavButtonGroup";
import NavFooter from "./NavFooter";
import NavHeader from "./NavHeader";
import NavScrollArea from "./NavScrollArea";
import NavSearch from "./NavSearch";
import {
  InternalNavLink,
  isGroupItem,
  isSeparator,
  NavItem,
  NavLink,
} from "./useNavItems";

interface NavPanelProps extends HTMLMotionProps<"div"> {
  width: number | string;
  items: NavItem[];
  onHideOverlay?: () => void;
  activeItem: InternalNavLink | undefined;
  transitionDuration: number;
}

const NavPanel = ({
  className,
  width,
  items,
  onHideOverlay,
  activeItem,
  transitionDuration,
  ...props
}: NavPanelProps) => {
  const asOverlay = !!onHideOverlay;

  const handleItemClick = useCallback(
    (item: NavLink) => {
      analyticsEvent(`${EventPrefix.Navbar}: Clicked ${item.label}`);
      item.onClick?.();
      onHideOverlay?.();
    },
    [onHideOverlay],
  );

  return (
    <motion.div
      {...props}
      className={clsx("z-10 flex flex-col bg-[#18181B]", className)}
      initial={asOverlay ? { opacity: 0, y: 100 } : false}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      style={{ width }}
      transition={{ duration: asOverlay ? 0.15 : transitionDuration }}
    >
      <NavHeader
        onLogoClick={onHideOverlay}
        onHideOverlay={asOverlay ? onHideOverlay : undefined}
      />

      <NavSearch onHideOverlay={asOverlay ? onHideOverlay : undefined} />

      <NavScrollArea
        className="min-h-0 flex-1"
        shadowClassName="from-[#18181B]"
      >
        <ul className="flex min-h-full flex-col">
          {items.map((item, index) =>
            isGroupItem(item) ? (
              <NavButtonGroup
                key={item.title}
                group={item}
                activeItem={activeItem}
              >
                {item.items.map((item) => (
                  <NavButton
                    key={item.label}
                    item={item}
                    activeItem={activeItem}
                    onClick={handleItemClick}
                  />
                ))}
              </NavButtonGroup>
            ) : isSeparator(item) ? (
              <li key={index} className="my-4">
                <hr className="border-0 border-t border-[#27272A]" />
              </li>
            ) : (
              <NavButton
                key={item.label}
                item={item}
                activeItem={activeItem}
                onClick={handleItemClick}
              />
            ),
          )}
        </ul>
      </NavScrollArea>

      <NavFooter onHideOverlay={asOverlay ? onHideOverlay : undefined} />
    </motion.div>
  );
};

export default NavPanel;
