import clsx from "clsx";
import { useCallback, useRef } from "react";
import { ViewAsPreservingLink } from "../../common/ViewAsPreservingLink";
import {
  InternalNavLink,
  NavLink,
  isExternalLink,
  isInternalLink,
} from "./useNavItems";

export interface NavButtonProps {
  item: NavLink;
  onClick?: (item: NavLink) => void;
  activeItem?: InternalNavLink;
}

export const buttonClassName = clsx(
  "relative my-px flex w-full items-center gap-2.5 truncate rounded-sm px-2 py-1 transition",
  "outline-hidden focus-visible:shadow-focus-dark",
  "text-paragraph-200-regular text-gray-50",
  "hover:bg-white/5 aria-current-page:bg-white/10 aria-current-page:text-white",
);

export const iconClassName = clsx(
  "my-1.5 flex size-4 items-center justify-center",
);

const labelClassName = clsx(
  "min-w-0 flex-1 overflow-hidden text-ellipsis whitespace-nowrap",
);

const NavButton = ({ item, onClick, activeItem }: NavButtonProps) => {
  const labelRef = useRef<HTMLSpanElement>(null);

  const external = isExternalLink(item);
  const internal = isInternalLink(item);

  const handleClick = useCallback(() => {
    onClick?.(item);
  }, [item, onClick]);

  const isActive = activeItem === item;
  const commonProps = {
    className: buttonClassName,
    onClick: handleClick,
    "aria-current": isActive ? ("page" as const) : undefined,
  };

  const commonContent = (
    <>
      {item.icon && <span className={iconClassName}>{item.icon}</span>}

      <span className={labelClassName} ref={labelRef}>
        {item.label}
      </span>

      {item.isNew && (
        <span className="rounded-sm bg-brand px-1.5 py-0.5 text-label-100 leading-normal">
          New
        </span>
      )}
    </>
  );

  return (
    <li>
      {external ? (
        <a
          href={item.href}
          target="_blank"
          rel="noreferrer noopener"
          {...commonProps}
        >
          {commonContent}
        </a>
      ) : internal ? (
        <ViewAsPreservingLink to={item.to} {...commonProps}>
          {commonContent}
        </ViewAsPreservingLink>
      ) : (
        <button {...commonProps}>{commonContent}</button>
      )}
    </li>
  );
};

export default NavButton;
