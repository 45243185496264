import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

export default function useStripeInSignup() {
  const stripe = useStripe();
  const elements = useElements();

  const [loadErrorMessage, setLoadErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const submitMutation = useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        throw new Error("Attempted to submit before stripe loaded");
      }

      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          // This should never happen, as redirects should never happen for card payments.
          return_url: globalThis.location.href,
        },
      });

      if (error) {
        // Stripe will sometimes automatically show the same errors inline in its form.
        throw error;
      }

      // payment_method can be a string or a PaymentMethod object
      const { payment_method } = paymentIntent;
      const paymentMethodId =
        typeof payment_method !== "string"
          ? payment_method?.id
          : payment_method;

      return {
        paymentMethodId,
      };
    },
  });

  const handleLoadError = (event: {
    elementType: "payment";
    error: StripeError;
  }) => {
    setLoadErrorMessage(event.error?.message);
  };

  return {
    // Stripe has finished loading
    isLoading: loadErrorMessage === undefined && (!stripe || !elements),
    // An error occurred while loading Stripe
    handleLoadError,
    loadErrorMessage,
    // Form is submitting
    submitMutation,
  };
}
