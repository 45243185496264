import { TeamSize, TeamType } from "@alch/dx-entities";
import { UserProfile01, UsersProfiles01 } from "@alch/ui/icons/16";
import Collapsible from "@features/signup/components/Collapsible";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import { useSignupForm } from "@features/signup/hooks/useSignupForm";
import { EventPrefix, analyticsEvent } from "@util/analytics";
import { HEAP_SCRIPT_ID } from "@util/analytics/heap";
import { isScriptLoaded } from "@util/analytics/utils";
import { useEffect } from "react";
import { TextField } from "../../../components/ui/index";
import PillRadioGroup, {
  PillRadioGroupVariant,
  PillRadioItem,
} from "../../../components/ui/pill-radio-group/PillRadioGroup";
import useCurrentUser from "../../../react-query/queries/useCurrentUser";
import { useSignupFlowFormData } from "../SignupFlowProvider";
import { getTeamNameFromEmail } from "../utils";
import SignupTemplatePage from "./SignupTemplatePage";

const CURRENT_STEP = SignupStep.Team;

const TEAM_TYPE_ITEMS: PillRadioItem<TeamType>[] = [
  {
    label: "Team",
    value: TeamType.Team,
    Icon: UsersProfiles01,
  },
  {
    label: "Personal",
    value: TeamType.Personal,
    Icon: UserProfile01,
  },
];

const TEAM_SIZE_ITEMS: PillRadioItem<TeamSize>[] = [
  {
    label: "Just me",
    value: TeamSize.One,
  },
  {
    label: "2-10",
    value: TeamSize.TwoToTen,
  },
  {
    label: "11-50",
    value: TeamSize.ElevenToFifty,
  },
  {
    label: "51+",
    value: TeamSize.FiftyOnePlus,
  },
];

const TeamPage = () => {
  const { data: user } = useCurrentUser();
  const { formData } = useSignupFlowFormData();

  const emailDomain = user?.email.split("@")[1] ?? "@alchemy.com";

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    watch,
    resetField,
    setValue,
  } = useSignupForm({
    schemaField: SignupSchemaField.Team,
    defaultValues: {
      [SignupFormFieldName.TeamType]: "",
      [SignupFormFieldName.TeamName]: "",
      [SignupFormFieldName.TeamSize]: "",
      [SignupFormFieldName.TeamEmailsToInvite]: "",
      [SignupFormFieldName.ProjectName]: "My first project",
    },
    onSubmit: () => {
      switch (teamType) {
        case TeamType.Team:
          resetField(SignupFormFieldName.ProjectName, { defaultValue: "" });
          break;
        case TeamType.Personal:
          resetField(SignupFormFieldName.TeamName, { defaultValue: "" });
          resetField(SignupFormFieldName.TeamSize, {
            defaultValue: "" as TeamSize,
          });
          resetField(SignupFormFieldName.TeamEmailsToInvite, {
            defaultValue: "",
          });
          break;
      }
    },
  });

  useEffect(() => {
    // Set default values after user is fetched
    if (user && !formData.team) {
      setValue(SignupFormFieldName.TeamName, getTeamNameFromEmail(user.email));
      setValue(
        SignupFormFieldName.ProjectName,
        `${user.firstName}'s first project`,
      );
    }
  }, [formData.team, setValue, user]);

  useEffect(() => {
    if (isScriptLoaded(HEAP_SCRIPT_ID)) {
      analyticsEvent(
        `${EventPrefix.SignupFlowRedesign}: Started signup (Loaded)`,
      );
    }
  }, []);

  useEffect(() => {
    // Only fire when user interacts with the page. This is a fallback event in case `Started signup (Loaded)` doesn't fire.
    if (isDirty) {
      analyticsEvent(
        `${EventPrefix.SignupFlowRedesign}: Started signup (Interacted)`,
      );
    }
  }, [isDirty]);

  const [teamType, teamSize] = watch([
    SignupFormFieldName.TeamType,
    SignupFormFieldName.TeamSize,
  ]);

  return (
    <SignupTemplatePage
      title="Who’s building?"
      subtitle="We'll use this info to streamline your experience."
      currentStep={CURRENT_STEP}
      disableNextButton={!isValid}
      onNextButtonClick={handleSubmit}
    >
      <PillRadioGroup
        items={TEAM_TYPE_ITEMS}
        control={control}
        name={SignupFormFieldName.TeamType}
      />
      <div className="h-6" />
      <Collapsible isOpen={!!teamType}>
        <div className="rounded-2xl border border-grayscale-200 bg-white px-4 py-6">
          <Collapsible
            isOpen={teamType === TeamType.Team}
            className="grid grid-cols-1 gap-y-6"
          >
            <TextField
              control={control}
              label="What's the name of your organization?"
              name={SignupFormFieldName.TeamName}
            />
            <PillRadioGroup
              items={TEAM_SIZE_ITEMS}
              control={control}
              name={SignupFormFieldName.TeamSize}
              variant={PillRadioGroupVariant.Small}
              label="How big is your team?"
            />
            <Collapsible isOpen={teamSize && teamSize !== TeamSize.One}>
              <TextField
                control={control}
                label="Invite your team (optional)"
                name={SignupFormFieldName.TeamEmailsToInvite}
                placeholder={`adam@${emailDomain}, bella@${emailDomain}`}
                inputContainerClassName="text-paragraph-200-regular "
              />
            </Collapsible>
          </Collapsible>
          <Collapsible isOpen={teamType === TeamType.Personal}>
            <TextField
              control={control}
              label="What's the name of your project?"
              name={SignupFormFieldName.ProjectName}
            />
          </Collapsible>
        </div>
      </Collapsible>
    </SignupTemplatePage>
  );
};

export default TeamPage;
