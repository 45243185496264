import { Route } from "@components/app/Route.tsx";
import ChainPage from "@features/signup/Pages/ChainPage";
import EnterpriseDetailsPage from "@features/signup/Pages/EnterpriseDetailsPage";
import FeatureFlaggedPaymentPage from "@features/signup/Pages/FeatureFlaggedPaymentPage";
import PlanPage from "@features/signup/Pages/PlanPage";
import ProjectPage from "@features/signup/Pages/ProjectPage";
import ReferrerPage from "@features/signup/Pages/ReferrerPage";
import TeamPage from "@features/signup/Pages/TeamPage";
import SignupFlowProvider from "@features/signup/SignupFlowProvider";
import {
  SignupStep,
  SignupStepPathMap,
  SignupStepsList,
} from "@features/signup/constants";
import { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import useAnalyticsInSignup from "../../hooks/useAnalyticsInSignup";
import useSignupDefaultLocation from "./hooks/useSignupDefaultLocation";

const SignupStepComponentMap = {
  [SignupStep.Team]: TeamPage,
  [SignupStep.Project]: ProjectPage,
  [SignupStep.Chain]: ChainPage,
  [SignupStep.Plan]: PlanPage,
  [SignupStep.EnterpriseDetails]: EnterpriseDetailsPage,
  [SignupStep.Payment]: FeatureFlaggedPaymentPage,
  [SignupStep.Referrer]: ReferrerPage,
};

const SignupRoutes = () => {
  useAnalyticsInSignup();

  const history = useHistory();
  const defaultLocation = useSignupDefaultLocation();

  useEffect(() => {
    // Send user back to start of signup flow if they refresh during signup
    history.replace(defaultLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once, on mount
  }, []);

  return (
    <SignupFlowProvider>
      <Switch>
        {SignupStepsList.map((step) => (
          <Route
            key={step}
            path={SignupStepPathMap[step]}
            component={SignupStepComponentMap[step]}
            exact
          />
        ))}
      </Switch>
    </SignupFlowProvider>
  );
};

export default SignupRoutes;
