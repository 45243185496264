import { TeamMonthlyPlan } from "@features/team/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { callEndpoint, Method } from "../../../../http/axiosConfig";
import usePlanOverride from "./usePlanOverride";

export const ACTIVE_PLAN_QUERY_KEY = ["active_plan"];

export default function useActivePlan<T = TeamMonthlyPlan>(
  opts?: Partial<UseQueryOptions<TeamMonthlyPlan, Error, T>>,
) {
  const {
    tier: tierOverride,
    term: termOverride,
    usageCapType: usageCapTypeOverride,
  } = usePlanOverride();

  return useQuery<TeamMonthlyPlan, Error, T>({
    ...opts,
    queryKey: ACTIVE_PLAN_QUERY_KEY,
    queryFn: () => {
      return callEndpoint<TeamMonthlyPlan>(
        Method.GET,
        "/api/team-monthly-plan",
      ).then((res) => ({
        ...res,
        tier: tierOverride !== undefined ? tierOverride : res.tier,
        plan_term: termOverride !== undefined ? termOverride : res.plan_term,
        usage_cap_type:
          usageCapTypeOverride !== undefined
            ? usageCapTypeOverride
            : res.usage_cap_type,
        active_webhook_limit: res.active_webhook_limit,
      }));
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });
}
