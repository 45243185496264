import {
  TeamPlanTerm,
  TeamPlanTier,
  TeamPlanUsageCapType,
} from "@alch/dx-entities";
import useCurrentUser from "@queries/useCurrentUser";
import { IS_PRODUCTION } from "@util/deployEnv";
import useSearchParam, { SearchParam } from "../../../../hooks/useSearchParam";

const stringToTier: Record<string, TeamPlanTier> = {
  free: TeamPlanTier.FREE,
  growth: TeamPlanTier.GROWTH,
  scale: TeamPlanTier.SCALE,
  enterprise: TeamPlanTier.ENTERPRISE,
  other: TeamPlanTier.OTHER,
  payg: TeamPlanTier.PAYG,
};
const stringToTerm: Record<string, TeamPlanTerm> = {
  monthly: TeamPlanTerm.MONTHLY,
  annual: TeamPlanTerm.ANNUAL,
};
const stringToTeamPlanUsageCapType: Record<string, TeamPlanUsageCapType> = {
  capped: TeamPlanUsageCapType.Capped,
  auto_scale: TeamPlanUsageCapType.AutoScale,
};

export interface PlanOverride {
  tier: TeamPlanTier | undefined;
  term: TeamPlanTerm | undefined;
  usageCapType: TeamPlanUsageCapType | undefined;
}

/**
 * Use a query param to test the user having a plan
 */
export default function usePlanOverride(): PlanOverride {
  const testPlan = useSearchParam(SearchParam.TestPlan) || "";
  const testTerm = useSearchParam(SearchParam.TestTerm) || "";
  const testTeamPlanUsageCapType =
    useSearchParam(SearchParam.TestUsageCapType) || "";
  const { data: user } = useCurrentUser();

  // Only enable this debug override in production for staff
  // [ddahmer] TODO: Remove the `isStaff` check after Billing ships 2025-03-01 for safety.
  if (IS_PRODUCTION && !user?.isStaff) {
    return { tier: undefined, term: undefined, usageCapType: undefined };
  }

  return {
    tier: stringToTier[testPlan],
    term: stringToTerm[testTerm],
    usageCapType: stringToTeamPlanUsageCapType[testTeamPlanUsageCapType],
  };
}
