import { TeamPlanTier } from "@alch/dx-entities";
import { IS_DEVELOPMENT } from "@util/deployEnv";
import { TIER_CONFIG } from "@util/payment/tierConfig";
import { dynamicScriptLoad } from "./utils";

interface MavaIdentifyProperties {
  emailAddress: string;
  customAttributes: Array<{ label: string; value: unknown }>;
}

declare global {
  interface Window {
    Mava?: {
      initialize(): void;
      identify(properties: MavaIdentifyProperties): void;
    };
  }
}

let mavaInitialized = false;
let mavaLoaded = false;

// Keep track of functions that need to be called after Mava is loaded
const mavaQueue: Array<() => unknown> = [];

export function initMava(debug: boolean) {
  if (mavaInitialized) return;

  mavaInitialized = true;

  dynamicScriptLoad("https://widget.mava.app", {
    "widget-version": "v2",
    id: "MavaWebChat",
    "enable-sdk": "true",
    "data-token": import.meta.env.VITE_MAVA_TOKEN || "",
    "debug-mode": "false",
  });

  window.addEventListener("loadMavaWebchat", () => {
    const Mava = window.Mava;
    if (typeof Mava?.initialize !== "function") {
      return;
    }

    Mava.initialize();

    if (debug) {
      console.debug("[Mava] loaded");
    }

    mavaLoaded = true;

    mavaQueue.forEach((fn) => fn());
  });
}

type IdentifyMavaUserArgs = [
  userId: number,
  values: {
    firstName: string;
    lastName: string;
    email: string;
    teamId?: number;
    teamName?: string;
    tier?: TeamPlanTier;
  },
  debug: boolean,
];

export function identifyMavaUser(...args: IdentifyMavaUserArgs) {
  if (!mavaLoaded) {
    mavaQueue.push(() => identifyMavaUser(...args));

    return;
  }

  const [userId, values, debug] = args;

  const Mava = window.Mava;
  if (typeof Mava?.identify !== "function") {
    return;
  }

  if (debug) {
    console.debug(
      IS_DEVELOPMENT
        ? "[Mava] skipping identify in development mode"
        : "[Mava] identify",
      userId,
    );
  }

  // Mava doesn't let you use localhost as an allowed host for sdk calls
  if (IS_DEVELOPMENT) {
    return;
  }

  Mava.identify({
    emailAddress: values.email,
    customAttributes: [
      { label: "User ID", value: userId },
      ...(values.teamId ? [{ label: "Team ID", value: values.teamId }] : []),
      ...(values.teamName
        ? [{ label: "Team Name", value: values.teamName }]
        : []),
      {
        label: "Full Name",
        value: `${values.firstName || ""} ${values.lastName || ""}`.trim(),
      },
      ...(values.tier != null
        ? [
            {
              label: "Customer Tier",
              value: TIER_CONFIG[values.tier].name,
            },
          ]
        : []),
    ],
  });
}
