import {
  TeamPlanTier,
  TIERED_CU_PRICING,
  TieredPricingConfig,
} from "@alch/dx-entities";

// Ordered by rank
export const ALL_TIERS: TeamPlanTier[] = [
  TeamPlanTier.FREE,
  TeamPlanTier.PAYG,
  TeamPlanTier.ENTERPRISE,
  TeamPlanTier.OTHER,
];

export const ENTERPRISE_TIERS = [TeamPlanTier.ENTERPRISE, TeamPlanTier.OTHER];

export const SELF_SERVICE_TIERS: TeamPlanTier[] = ALL_TIERS.filter(
  (p) => !ENTERPRISE_TIERS.includes(p),
);

export const PAID_SELF_SERVICE_TIERS: TeamPlanTier[] =
  SELF_SERVICE_TIERS.filter((p) => p !== TeamPlanTier.FREE);

export const PICKER_TIERS: TeamPlanTier[] = [
  TeamPlanTier.PAYG,
  TeamPlanTier.ENTERPRISE,
];

export interface TierConfig {
  name: string;
  baseCost: number;
  includedUnits: number;
  onDemandCPM: number | TieredPricingConfig[];
  supportsOnDemand: boolean;
  preAuthCharge?: number;
  throughput: number;
  numOfApps: number;
  numOfActiveWebhooks: number;
  numOfRollups: number;
  tagline: string;
  capabilities: {
    debugApi: boolean;
    traceApi: boolean;
    gasManager: "mainnet" | "testnet";
  };
}

/**
 * Configs for each plan tier.
 */
export const TIER_CONFIG: Record<TeamPlanTier, TierConfig> = {
  [TeamPlanTier.FREE]: {
    name: "Free",
    baseCost: 0,
    includedUnits: 100_000_000,
    onDemandCPM: 0,
    supportsOnDemand: false,
    throughput: 1000,
    numOfApps: 5,
    numOfActiveWebhooks: 5,
    numOfRollups: 0,
    tagline: "The largest & most powerful free tier in web3.",
    capabilities: {
      debugApi: false,
      traceApi: false,
      gasManager: "testnet",
    },
  },
  [TeamPlanTier.GROWTH]: {
    name: "Growth",
    baseCost: 49.0,
    includedUnits: 400_000_000,
    onDemandCPM: 1.2,
    supportsOnDemand: true,
    throughput: 660,
    numOfApps: 15,
    numOfActiveWebhooks: 0,
    numOfRollups: 1,
    tagline: "Grows with your business.",
    capabilities: {
      debugApi: true,
      traceApi: true,
      gasManager: "mainnet",
    },
  },
  [TeamPlanTier.SCALE]: {
    name: "Scale",
    baseCost: 289.0,
    includedUnits: 1_500_000_000,
    onDemandCPM: 1.0,
    supportsOnDemand: true,
    throughput: 3000,
    numOfApps: 30,
    numOfActiveWebhooks: 0,
    numOfRollups: 1,
    tagline:
      "The cheapest option in web3; enterprise discounts, no humans required.",
    capabilities: {
      debugApi: true,
      traceApi: true,
      gasManager: "mainnet",
    },
  },
  [TeamPlanTier.ENTERPRISE]: {
    name: "Enterprise",
    baseCost: 0,
    includedUnits: 0,
    onDemandCPM: 0,
    supportsOnDemand: true,
    throughput: 0,
    numOfApps: 0,
    numOfActiveWebhooks: 500,
    numOfRollups: 1,
    tagline: "Volume pricing, unmatched support, custom SLAs.",
    capabilities: {
      debugApi: true,
      traceApi: true,
      gasManager: "mainnet",
    },
  },
  [TeamPlanTier.OTHER]: {
    name: "Other",
    baseCost: 0,
    includedUnits: 0,
    onDemandCPM: 0,
    supportsOnDemand: true,
    throughput: 0,
    numOfApps: 0,
    numOfActiveWebhooks: 500,
    numOfRollups: 1,
    tagline: "Volume pricing, unmatched support, custom SLAs.",
    capabilities: {
      debugApi: true,
      traceApi: true,
      gasManager: "mainnet",
    },
  },
  [TeamPlanTier.PAYG]: {
    name: "Pay As You Go",
    baseCost: 0,
    // preAuthCharge: 49,
    includedUnits: 0,
    onDemandCPM: TIERED_CU_PRICING[TeamPlanTier.PAYG] ?? 0,
    supportsOnDemand: true,
    throughput: 10000,
    numOfApps: 30,
    numOfActiveWebhooks: 100,
    numOfRollups: 1,
    tagline: "Pay for what you use. No monthly fees.",
    capabilities: {
      debugApi: true,
      traceApi: true,
      gasManager: "mainnet",
    },
  },
};
