import { isEmpty, isNil, pickBy } from "lodash";

export const omitEmptyValues = <T extends object>(obj: T) => {
  return pickBy(
    obj,
    (val) =>
      // isEmpty works only for collections and strings
      typeof val === "boolean" ||
      (typeof val === "number" && !Number.isNaN(val)) ||
      !isEmpty(val),
  );
};

export const omitNilValues = <T extends object>(obj: T) => {
  return pickBy(obj, (val) => !isNil(val));
};

export const hasProperty = <T extends object>(
  obj: T,
  prop: PropertyKey,
): prop is keyof T => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
};

/**
 * Converts an object into an array of strings in the format "key:value"
 * Handles various types including arrays, numbers, and enums
 *
 * @param {T} sourceObject - The source object to convert
 * @returns {string[]} An array of strings where each string is formatted as "key:value"
 */
export function objectToKeyValueStrings<T extends object>(
  sourceObject: T,
): string[] {
  return Object.entries(sourceObject).map(([key, value]) => {
    let stringValue: string;

    if (Array.isArray(value)) {
      stringValue = value.join("-");
    } else if (value === null || value === undefined) {
      stringValue = "";
    } else {
      stringValue = String(value);
    }

    return `${key}:${stringValue}`;
  });
}
