import { AnimatePresence } from "framer-motion";
import { useCallback } from "react";
import NavPanel from "./NavPanel";
import useNavActiveItem from "./useNavActiveItem";
import useNavItems from "./useNavItems";

// Can go back to 216 once we don't have the `NEW` badge on the Block Timestamp API
export const NAV_WIDTH = 256;

interface NavigationProps {
  showOverlay: boolean;
  setShowOverlay: (show: boolean) => void;
  withOverlays: boolean;
}

const Navigation = ({
  showOverlay,
  setShowOverlay,
  withOverlays,
}: NavigationProps) => {
  const items = useNavItems();
  const activeItem = useNavActiveItem(items);

  const handleHideOverlay = useCallback(
    () => setShowOverlay(false),
    [setShowOverlay],
  );

  const transitionDuration = withOverlays ? 0 : 0.2;

  return (
    <div role="navigation" aria-label="Main">
      {withOverlays ? null : <div style={{ width: NAV_WIDTH }} />}

      <AnimatePresence initial={false}>
        {withOverlays && !showOverlay ? null : (
          <NavPanel
            className="fixed z-30 max-md:inset-0 md:inset-y-0"
            width={withOverlays ? "100%" : NAV_WIDTH}
            items={items}
            onHideOverlay={withOverlays ? handleHideOverlay : undefined}
            activeItem={activeItem}
            transitionDuration={transitionDuration}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navigation;
