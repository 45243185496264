import { SearchParam } from "@hooks/useSearchParam";
import { preserveQueryParamsInPath } from "./urls";

type ViewAsParams = {
  [SearchParam.ViewAsUser]?: string;
  [SearchParam.ViewAsTeam]?: string;
};

/**
 * Returns the view_as_user and view_as_team values in the current URL, if they
 * exist.
 */
export function getViewAsId(): ViewAsParams | undefined {
  const viewAsUserString = new URLSearchParams(window.location.search).get(
    SearchParam.ViewAsUser,
  );
  // view_as is deprecated for view_as_user but still needs to be supported
  const viewAsString = new URLSearchParams(window.location.search).get(
    "view_as",
  );

  if (viewAsUserString) {
    return { [SearchParam.ViewAsUser]: viewAsUserString };
  } else if (viewAsString) {
    return { [SearchParam.ViewAsUser]: viewAsString };
  }

  const viewAsTeamString = new URLSearchParams(window.location.search).get(
    SearchParam.ViewAsTeam,
  );
  if (viewAsTeamString) {
    return { [SearchParam.ViewAsTeam]: viewAsTeamString };
  }
  return undefined;
}

/**
 * [DEPRECATED] Returns the view_as_user and view_as_team parameters as a query string without
 * the question mark.
 * For example "view_as_user=123".
 * @deprecated Use `preserveViewAsInPath` instead.
 */
export function getViewParam(
  params: ViewAsParams & { view_as?: string },
): string {
  const searchParams = new URLSearchParams();
  if (params.view_as_user) {
    searchParams.set(SearchParam.ViewAsUser, params.view_as_user);
  } else if (params.view_as) {
    // view_as is deprecated for view_as_user but still needs to be supported
    searchParams.set("view_as", params.view_as);
  }
  if (params.view_as_team) {
    searchParams.set(SearchParam.ViewAsTeam, params.view_as_team);
  }
  return searchParams.toString();
}

/**
 * Copies over the view_as_user and view_as_team parameters from the current URL
 * to the given URL path, if they exist.
 * @param currentUrlOrPathStr location.href
 */
export function preserveViewAsInPath(
  currentUrlOrPathStr: string,
  targetPathStr: string,
) {
  return preserveQueryParamsInPath(currentUrlOrPathStr, targetPathStr, [
    SearchParam.ViewAsUser,
    SearchParam.ViewAsTeam,
    // view_as is deprecated for view_as_user but still needs to be supported
    "view_as",
  ]);
}
