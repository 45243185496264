import { TeamPlanTier } from "@alch/dx-entities";
import { priceTextClassName } from "@features/signup/Pages/PaymentPage/PaymentSummaryCard";
import { PlanSelection, usePlans } from "@features/signup/Pages/PlanPage";
import { TIER_CONFIG } from "@util/payment/tierConfig";

const getTierPricePerMonth = ({
  plans,
  tier,
}: {
  plans: PlanSelection[];
  tier: TeamPlanTier;
}) => {
  return plans.find((plan) => plan.tier === tier)?.monthlyPrice;
};

interface SelectedTierLabelProps {
  tier: TeamPlanTier;
}

const SelectedTierLabel = ({ tier }: SelectedTierLabelProps) => {
  const plans = usePlans();
  return (
    <div className="flex">
      <p className={priceTextClassName}>
        {`${TIER_CONFIG[tier].name} -`}&nbsp;
      </p>
      <p className={priceTextClassName}>
        {getTierPricePerMonth({ tier, plans })}
      </p>
    </div>
  );
};

export default SelectedTierLabel;
