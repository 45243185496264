import { TextField } from "@alch/ui";
import * as Sentry from "@sentry/react";
import { logException } from "@util/errors";
import clsx from "clsx";
import { camelCase } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import usePlacesAutocomplete, {
  Suggestion,
  getDetails,
} from "use-places-autocomplete";
import { useOnClickOutside, useScript } from "usehooks-ts";

const PLACES_API_KEY = "AIzaSyA6-ylAQCq-e9FZONhU8i5XkkiGG-3uuO8";

export interface PlacesAddress {
  streetNumber: string;
  route: string;
  neighborhood: string;
  political: string;
  locality: string;
  administrativeAreaLevel2: string;
  administrativeAreaLevel1: string;
  country: string;
  postalCode: string;
  postalCodeSuffix: string;
  name: string;
}

// This type guard is needed to ensure that the details object is a PlaceResult
function isPlaceResult(
  details: string | google.maps.places.PlaceResult,
): details is google.maps.places.PlaceResult {
  return typeof details !== "string";
}

interface PlacesAutoCompleteDropdownProps {
  onSelectAddress: (address: PlacesAddress) => void;
  onAddressChange: (addressString: string) => void;
}

const PlacesAutoCompleteDropdown = ({
  onSelectAddress,
  onAddressChange,
}: PlacesAutoCompleteDropdownProps) => {
  const scriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`,
  );
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const autoCompleteFieldRef = useRef(null);

  useOnClickOutside(autoCompleteFieldRef, () => {
    setIsAutoCompleteOpen(false);
  });

  const {
    init: initAutoComplete,
    value: autoCompleteValue,
    suggestions: { status: autoCompleteStatus, data: autoCompleteData },
    setValue: setAutoCompleteValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false,
  });

  useEffect(() => {
    if (scriptStatus === "error") {
      Sentry.captureMessage(
        "Failed to load Google Places API for address autocomplete",
        "warning",
      );
      return;
    }

    if (scriptStatus === "ready") {
      initAutoComplete();
    }
  }, [initAutoComplete, scriptStatus]);

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoCompleteValue(event.target.value);
    onAddressChange(event.target.value);
  };

  const handleSelectAddress = useCallback(
    ({ place_id, structured_formatting: { main_text: address } }: Suggestion) =>
      async () => {
        setAutoCompleteValue(address, false);
        setIsAutoCompleteOpen(false);

        try {
          const details = await getDetails({
            placeId: place_id,
          });

          if (isPlaceResult(details) && details.address_components) {
            const parsedAddress = details.address_components.reduce<
              Record<keyof PlacesAddress, string>
            >(
              (res, { long_name, types }) => {
                types.forEach((type) => {
                  res[camelCase(type) as keyof PlacesAddress] = long_name;
                });

                return res;
              },
              {} as Record<keyof PlacesAddress, string>,
            );

            parsedAddress.name = address;
            onSelectAddress(parsedAddress);
          }
        } catch (error) {
          logException(error);
        }
      },
    [onSelectAddress, setAutoCompleteValue],
  );

  const renderSuggestions = useCallback(
    () =>
      autoCompleteData.map((suggestion, index) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <li
            className={clsx(
              "px-2 py-1 hover:bg-blue-50",
              index < autoCompleteData.length - 1 &&
                "border-b border-grayscale-300",
            )}
            key={place_id}
            onClick={handleSelectAddress(suggestion)}
          >
            <span className="text-paragraph-200-medium">{main_text}</span>{" "}
            <span className="text-paragraph-100-regular">{secondary_text}</span>
          </li>
        );
      }),
    [autoCompleteData, handleSelectAddress],
  );

  return (
    <div className="mt-6 grid grid-cols-1 gap-y-6">
      <div ref={autoCompleteFieldRef}>
        <TextField
          required
          label="Address"
          value={autoCompleteValue}
          onChange={handleAddressChange}
          onFocus={() => setIsAutoCompleteOpen(true)}
          inputContainerClassName={clsx(
            autoCompleteStatus === "OK" &&
              isAutoCompleteOpen &&
              "rounded-b-none",
          )}
          autoComplete="address-line1"
        />
        {autoCompleteStatus === "OK" && isAutoCompleteOpen && (
          <ul className="overflow-hidden rounded-b-lg border border-grayscale-300">
            {renderSuggestions()}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PlacesAutoCompleteDropdown;
