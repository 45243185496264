import { CompleteSignupRequestParams } from "@features/signup/types";
import { SearchParam } from "@hooks/useSearchParam";
import { useMutation } from "@tanstack/react-query";
import { trpc } from "@util/trpc/trpcClient";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { completeSignup } from "../../http/endpoints";

function useCompleteSignup() {
  const location = useLocation();
  const history = useHistory();
  const utils = trpc.useUtils();

  const handleSuccess = useCallback(() => {
    // Invalidate the user key, so `hasCompletedSignup` will be re-fetched and the user will be redirected
    void utils.users.getUser.invalidate();

    // Remove test signup param from url
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(SearchParam.TestSignup)) {
      searchParams.delete(SearchParam.TestSignup);
      history.replace({ ...location, search: searchParams.toString() });
    }
  }, [utils.users.getUser, history, location]);

  return useMutation({
    mutationFn: (params: CompleteSignupRequestParams) => completeSignup(params),
    onSuccess: handleSuccess,
  });
}

export default useCompleteSignup;
