import { AnnouncementBanner } from "@alch/ui";
import useCurrentUser from "@queries/useCurrentUser";

import useTeam from "@queries/useTeam";
import { getFullName } from "@util/strings.ts";
import { getViewAsId } from "@util/viewAs.ts";

const ViewingAsBanner = () => {
  const { data: user } = useCurrentUser();
  const { data: team } = useTeam();
  const viewAsId = getViewAsId();

  if (!user || !team || !viewAsId) {
    return null;
  }

  return (
    <AnnouncementBanner intent="warning">
      You are viewing this page in read-only mode as{" "}
      <strong>
        {user &&
          getFullName(
            { firstName: user.firstName, lastName: user.lastName },
            user.email,
          )}{" "}
        ({team && team.name})
      </strong>
      .{" "}
      <AnnouncementBanner.Link href="/">
        Return to your normal view
      </AnnouncementBanner.Link>
      .
    </AnnouncementBanner>
  );
};

export default ViewingAsBanner;
