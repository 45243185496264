import { TeamPlanTier } from "@alch/dx-entities";
import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import {
  SignupStep,
  SignupStepPathMap,
  SignupStepsList,
} from "@features/signup/constants";
import { EventPrefix, analyticsEvent } from "@util/analytics";
import { useHistory } from "react-router-dom";

interface UseNavigateProps {
  currentStep: SignupStep;
}

const useNavigateSignup = ({ currentStep }: UseNavigateProps) => {
  const { formData } = useSignupFlowFormData();

  const history = useHistory();

  // Form what the step list should be based on selected values
  let steps = [...SignupStepsList];
  if (formData.plan?.planType !== TeamPlanTier.ENTERPRISE) {
    // Only enterprise sees the Enterprise Details page
    steps = steps.filter((step) => step !== SignupStep.EnterpriseDetails);
  }
  if (formData.plan?.planType === TeamPlanTier.FREE) {
    // Free tier doesn't accept credit cards
    steps = steps.filter((step) => step !== SignupStep.Payment);
  }

  const currentStepIndex = steps.indexOf(currentStep);
  if (currentStepIndex === -1) {
    throw new Error(
      `Current step ${currentStep} not in steps list ${steps.join(",")}`,
    );
  }
  const previousPath = SignupStepPathMap[steps[currentStepIndex - 1]];
  const nextPath = SignupStepPathMap[steps[currentStepIndex + 1]];

  return {
    previousPath,
    nextPath,
    goToNextStep: () => {
      analyticsEvent(
        `${EventPrefix.SignupFlowRedesign}: Completed ${currentStep} step`,
      );
      if (nextPath) {
        history.push({ ...history.location, pathname: nextPath });
      }
    },
    goToPreviousStep: () => {
      if (previousPath) {
        history.push({ ...history.location, pathname: previousPath });
      }
    },
  };
};

export default useNavigateSignup;
