import { App } from "@features/apps/types";
import {
  useQuery,
  UseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { getTeamApps } from "../../http/endpoints";

export const APPS_QUERY_KEY = ["apps"];

/**
 * @deprecated This hook and it's underlying endpoint is deprecated in favor of trpc.apps.getApps()
 */
function useApps<T = App[]>(opts?: Partial<UseQueryOptions<App[], Error, T>>) {
  return useQuery({
    ...opts,
    queryKey: APPS_QUERY_KEY,
    queryFn: () => getTeamApps(),
  });
}

export function useSuspenseApps<T = App[]>(
  opts?: Partial<UseQueryOptions<App[], Error, T>>,
) {
  return useSuspenseQuery({
    ...opts,
    queryKey: APPS_QUERY_KEY,
    queryFn: () => getTeamApps(),
  });
}

export default useApps;
