import { Action, combineReducers, Reducer } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
// eslint-disable-next-line import/no-cycle -- Ignoring all legacy import cycles
import { paymentsReducer, PaymentsState } from "./payments";

export interface RootState {
  payments: PaymentsState;
}

// eslint-disable-next-line @typescript-eslint/ban-types -- FIXME
export type AppDispatch = ThunkDispatch<RootState, {}, Action>;
// eslint-disable-next-line @typescript-eslint/ban-types -- FIXME
export type AppThunkAction<R> = ThunkAction<R, RootState, {}, Action>;

export const rootReducer: Reducer<RootState> = combineReducers({
  payments: paymentsReducer,
});
