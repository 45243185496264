import useCurrentUser from "@queries/useCurrentUser";
import { initAnalytics, setAnalyticsUser } from "@util/analytics";
import { useEffect } from "react";

initAnalytics();

/**
 * During signup we don't have any team details, so only identify the user with what we can
 */
export default function useAnalyticsInSignup() {
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      setAnalyticsUser({
        extId: user.extId,
        internalId: user.id,
        email: user.email,
        isStaff: false,
        createdAt: user.createdAt,
        isBillingAdmin: false,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      });
    }
  }, [user]);
}
