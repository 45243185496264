import { TRPCRouter } from "@alch/dx-webapp-api";
import { SearchParam } from "@hooks/useSearchParam";
import type { QueryClient } from "@tanstack/react-query";
import {
  createTRPCReact,
  TRPCClientError,
  TRPCLink,
  TRPCUntypedClient,
} from "@trpc/react-query";
import { AnyTRPCRouter } from "@trpc/server";
import { observable } from "@trpc/server/observable";
import { getViewAsId } from "@util/viewAs";
import { createContext } from "react";
import { getStoredAuthToken } from "../auth";

export interface TrpcProviderProps<TRouter extends AnyTRPCRouter> {
  trpc: ReturnType<typeof createTRPCReact<TRouter>>;
  trpcClient: TRPCUntypedClient<TRouter>;
  reactQueryContext?: ReturnType<typeof createContext<QueryClient | undefined>>;
}

// Middleware to block mutations when viewing as another team or user
export const blockImpersonationMutations: TRPCLink<TRPCRouter> = () => {
  return ({ op, next }) => {
    const viewAsId = getViewAsId();

    if (viewAsId && op.type !== "query") {
      return observable((observer) => {
        observer.error(
          new TRPCClientError(
            "Cannot perform mutations while viewing as someone else.",
          ),
        );
      });
    }

    return next(op);
  };
};

export function createHttpLinksOpt(apiOrigin: string) {
  return {
    url: `${apiOrigin}/trpc`,
    headers() {
      const authToken = getStoredAuthToken();
      if (!authToken) return {};

      return {
        Authorization: `Bearer ${authToken}`,
      };
    },
    fetch: (
      url: string | URL | RequestInfo,
      options: RequestInit | undefined,
    ) => {
      let modifiedUrl = url;

      // This is always true
      if (typeof url === "string") {
        const { view_as_user, view_as_team } = getViewAsId() ?? {};

        const urlObj = new URL(url);
        if (view_as_user)
          urlObj.searchParams.set(SearchParam.ViewAsUser, view_as_user);
        if (view_as_team)
          urlObj.searchParams.set(SearchParam.ViewAsTeam, view_as_team);
        modifiedUrl = urlObj.toString();
      }

      return fetch(modifiedUrl, {
        ...options,
        // This is required for deviceId cookie used for flags
        credentials: "include",
        // Send the page URL the fetch call was made from so backend sentry can see it
        referrerPolicy: "no-referrer-when-downgrade",
      });
    },
  };
}
