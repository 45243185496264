import { Command } from "@alch/ui";

import useTeamSupportUrl from "@queries/useTeamSupportUrl";
import { DEBUG_ANALYTICS } from "@util/analytics";
import { initMava } from "@util/analytics/mava";
import { useEffect } from "react";

interface SupportFABProps {
  hidden: boolean;
}

const SupportFAB = ({ hidden }: SupportFABProps) => {
  const teamSupport = useTeamSupportUrl();

  const showMava = teamSupport.data ? !teamSupport.data.isEnterprise : null;

  useEffect(() => {
    if (showMava) {
      initMava(DEBUG_ANALYTICS);
    }
  }, [showMava]);

  if (hidden) {
    return (
      <style dangerouslySetInnerHTML={{ __html: `#mava { display: none; }` }} />
    );
  }

  return (
    <>
      {/* A placeholder element to prevent FAB from going over website elements without an option to scroll over it */}
      <div className="h-16" />

      {showMava === false ? (
        <Command
          as="a"
          target="_blank"
          rel="noreferrer noopener"
          href={teamSupport.data?.url}
          background="dark"
          className="fixed right-5 bottom-5 z-20 flex h-10 w-24 items-center justify-center rounded-full bg-grayscale-950 text-paragraph-100-medium text-white"
        >
          Get Support
        </Command>
      ) : null}
    </>
  );
};

export default SupportFAB;
