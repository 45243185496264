import { Button, InlineAlert, Loading, useBreakpointValue } from "@alch/ui";
import { AlchemyBlack, AlchemyMark } from "@alch/ui/assets/logos";
import { ArrowLeft, ArrowRight } from "@alch/ui/icons/24";
import ProgressBar from "@features/signup/ProgressBar";
import { SignupStep, SignupStepsList } from "@features/signup/constants";
import useNavigateSignup from "@features/signup/hooks/useNavigateSignup";
import vectorBackground from "@static/signup/vector-background.svg";
import { Url } from "@util/urls";
import clsx from "clsx";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useUpdateEffect } from "usehooks-ts";
import useCurrentUser from "../../../react-query/queries/useCurrentUser";
import ProgressCount from "../ProgressCount";
import useSubmitSignupData from "../hooks/useSubmitSignupData";

interface SignupTemplatePageProps {
  title: string;
  subtitle?: string | React.ReactNode;
  currentStep: SignupStep;
  onPreviousButtonClick?: () => void;
  onNextButtonClick?: () => void;
  enableSkipButton?: boolean;
  children: React.ReactNode;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  errorMessage?: string | null;
  isSubmitting?: boolean;
  formSubmitOverride?: boolean;
  isLoading?: boolean;
}

const SignupTemplatePage = forwardRef(function SignupTemplatePage(
  {
    title,
    subtitle,
    currentStep,
    onPreviousButtonClick,
    onNextButtonClick,
    enableSkipButton = false,
    children,
    disableNextButton = false,
    hideNextButton = false,
    errorMessage,
    isSubmitting,
    formSubmitOverride,
    isLoading = false,
  }: SignupTemplatePageProps,
  ref: React.Ref<HTMLFormElement>,
) {
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const { data: user } = useCurrentUser();
  const { previousPath, nextPath, goToNextStep, goToPreviousStep } =
    useNavigateSignup({ currentStep });
  const {
    completeSignup,
    error: completeSignupError,
    isPending: isCompleteSignupPending,
  } = useSubmitSignupData();

  useUpdateEffect(() => {
    if (formSubmitOverride || hasSubmittedForm) {
      goToNextStep();

      if (!nextPath) {
        completeSignup();
      }
    }
  }, [formSubmitOverride, hasSubmittedForm]);

  useEffect(() => {
    if (!isCompleteSignupPending && hasSubmittedForm) {
      setHasSubmittedForm(false);
    }
  }, [isCompleteSignupPending, hasSubmittedForm]);

  const stepIndex = SignupStepsList.indexOf(currentStep);
  const enterpriseStepIndex = SignupStepsList.indexOf(
    SignupStep.EnterpriseDetails,
  );

  const logo = useBreakpointValue({
    base: <img src={AlchemyMark} alt="Alchemy Logo" width={25} height={25} />,
    md: <img src={AlchemyBlack} alt="Alchemy Logo" width={130} height={25} />,
  });

  const username = useBreakpointValue({
    base: user?.firstName.toLowerCase(),
    md: user?.email,
  });

  const progress = useBreakpointValue({
    base: (
      <ProgressCount
        // Exclude enterprise details step
        currentStep={
          stepIndex >= enterpriseStepIndex ? stepIndex : stepIndex + 1
        }
        totalSteps={SignupStepsList.length - 1}
      />
    ),
    md: <ProgressBar steps={SignupStepsList} currentStep={currentStep} />,
  });

  const prevAndNextButtons = useMemo(
    () => (
      <div
        className={clsx(
          "flex",
          previousPath ? "justify-between" : "justify-end",
        )}
      >
        {previousPath && (
          <Button
            type="reset"
            intent="tertiary"
            icon={<ArrowLeft />}
            onClick={() => {
              onPreviousButtonClick?.();
              goToPreviousStep();
            }}
          />
        )}
        <div className="flex grid-cols-2 gap-x-4">
          {enableSkipButton && !isCompleteSignupPending && (
            <Button
              intent="tertiary"
              onClick={() => {
                setHasSubmittedForm(true);
                goToNextStep();
              }}
            >
              Skip bonus
            </Button>
          )}
          {!hideNextButton && (
            <Button
              type="button"
              loading={isSubmitting || isCompleteSignupPending}
              trailingIcon={
                currentStep !== SignupStep.Referrer && <ArrowRight />
              }
              onClick={() => {
                onNextButtonClick?.();
                // Payment form requires additional verification through Chargify
                if (formSubmitOverride == null) {
                  setHasSubmittedForm(true);
                }
              }}
              disabled={disableNextButton}
            >
              {nextPath ? "Next" : "Let's build!"}
            </Button>
          )}
        </div>
      </div>
    ),
    [
      currentStep,
      disableNextButton,
      hideNextButton,
      enableSkipButton,
      goToNextStep,
      goToPreviousStep,
      isSubmitting,
      isCompleteSignupPending,
      onPreviousButtonClick,
      onNextButtonClick,
      previousPath,
      nextPath,
      formSubmitOverride,
    ],
  );

  const navigationButtons = useBreakpointValue({
    base: (
      <div className="fixed bottom-0 left-0 w-screen bg-white/80 p-4 backdrop-blur-[6px]">
        {prevAndNextButtons}
      </div>
    ),
    md: <div className="pb-6">{prevAndNextButtons}</div>,
  });

  return (
    <div
      style={{ backgroundImage: `url(${vectorBackground})` }}
      className={clsx("min-h-screen bg-cover bg-bottom bg-no-repeat pb-[70px]")}
    >
      <div className="grid grid-cols-3 px-6 py-3">
        <div className="flex justify-start">
          <a
            className="text-black"
            href={Url.Home}
            target="_blank"
            rel="noreferrer"
          >
            {logo}
          </a>
        </div>
        <div className="flex justify-center">{progress}</div>
        <div className="flex justify-end">
          <p className="text-paragraph-200-regular text-grayscale-700">
            {username}
          </p>
        </div>
      </div>
      <form ref={ref}>
        <div className="flex justify-center">
          <div className="inline-block justify-center px-3">
            <div className="my-6 flex flex-col items-center">
              <div className="text-center text-heading-h2 text-grayscale-950 md:text-heading-h1">
                {title}
              </div>
              <div className="h-3"></div>
              <div className="text-center text-paragraph-200-regular text-grayscale-700 md:text-paragraph-300-regular">
                {subtitle}
              </div>
            </div>
            <div className="my-12 flex flex-col">
              {isLoading ? <Loading className="self-center" /> : children}
            </div>
            {(!!completeSignupError || !!errorMessage) && (
              <InlineAlert
                intent="error"
                leadingLabel="Error"
                className="mb-3 w-full"
              >
                {errorMessage ||
                  (completeSignupError as Error)?.message ||
                  "Something went wrong. Could not complete sign up."}
              </InlineAlert>
            )}
            {navigationButtons}
          </div>
        </div>
      </form>
    </div>
  );
});

export default SignupTemplatePage;
