import { Modal } from "@alch/ui";
import { Search01 } from "@alch/ui/icons/16";
import { analyticsEvent, EventPrefix } from "@util/analytics";
import clsx from "clsx";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useBoolean } from "usehooks-ts";
import Search from "../search/Search";
import { buttonClassName, iconClassName } from "./NavButton";

interface NavSearchProps {
  onHideOverlay?: () => void;
}

const NavSearch = ({ onHideOverlay }: NavSearchProps) => {
  const modalBool = useBoolean();

  useHotkeys("mod+k", () => modalBool.setTrue());

  useEffect(() => {
    if (modalBool.value) {
      analyticsEvent(`${EventPrefix.Navbar}: Opened search`);
    }
  }, [modalBool.value]);

  return (
    <div className="px-4">
      <button
        className={clsx(buttonClassName, "bg-black/80")}
        onClick={modalBool.setTrue}
      >
        Search
        <Search01 className={clsx("ml-auto", iconClassName)} />
      </button>

      <Modal
        isOpen={modalBool.value}
        onClose={modalBool.setFalse}
        title="Search"
      >
        <div className="mb-80">
          <Search
            setShowOverlay={(show) => {
              modalBool.setValue(show);

              if (!show) {
                onHideOverlay?.();
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default NavSearch;
