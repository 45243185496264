import { AnnouncementBanner } from "@alch/ui";
import NewFeatureAnnouncementBanner from "@features/banners/NewFeatureAnnouncementBanner";
import { OneTimeEvent } from "@util/localStorage";

const SimpleHashBanner = () => {
  return (
    <NewFeatureAnnouncementBanner
      oneTimeEvent={OneTimeEvent.SimpleHashMigration}
    >
      To all builders affected by SimpleHash&apos;s API deprecation on March 27:
      we&apos;re here for you!{" "}
      <AnnouncementBanner.Link
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.alchemy.com/blog/migrating-from-simplehash-to-alchemy"
      >
        Migration guide
      </AnnouncementBanner.Link>
    </NewFeatureAnnouncementBanner>
  );
};

export default SimpleHashBanner;
