import { AppLayoutContextProvider } from "@features/app-layout/LayoutContext";
import { queryClient } from "@http/query.ts";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IS_DEVELOPMENT } from "@util/deployEnv.ts";
import { RaasTrpc } from "@util/trpc/raasTrpcClient";
import { WebappTrpc } from "@util/trpc/trpcClient.ts";
import { History } from "history";
import { Provider as ReduxProvider } from "react-redux";
import { Route, Router } from "react-router-dom";
import { AnyAction, Store } from "redux";
import { ThemeUIProvider } from "theme-ui";
import { QueryParamProvider } from "use-query-params";
import { RootState } from "../../redux/root";
import theme from "../../styles/theme";

interface ProvidersProps {
  store: Store<RootState, AnyAction>;
  history: History;
  children?: React.ReactNode;
}

/**
 * Global context providers for the app.
 *
 * Do not put any code here that requires the user to be authenticated.  They need to be located
 * below the AuthedRoot component.
 */
const Providers = ({ store, history, children }: ProvidersProps) => {
  return (
    <ReduxProvider store={store}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeUIProvider theme={theme}>
            <AppLayoutContextProvider>
              {/* See example here to register multiple trpc providers https://github.com/trpc/trpc/pull/3049*/}
              <WebappTrpc.trpc.Provider
                client={WebappTrpc.trpcClient}
                queryClient={queryClient}
              >
                <RaasTrpc.trpc.Provider
                  client={RaasTrpc.trpcClient}
                  queryClient={queryClient}
                >
                  <QueryClientProvider client={queryClient}>
                    {IS_DEVELOPMENT && (
                      <ReactQueryDevtools buttonPosition="bottom-left" />
                    )}
                    {children}
                  </QueryClientProvider>
                </RaasTrpc.trpc.Provider>
              </WebappTrpc.trpc.Provider>
            </AppLayoutContextProvider>
          </ThemeUIProvider>
        </QueryParamProvider>
      </Router>
    </ReduxProvider>
  );
};

export default Providers;
