import { TeamPlanTier } from "@alch/dx-entities";
import { Button } from "@alch/ui";
import { CheckContained } from "@alch/ui/icons/16";
import { ChevronRight } from "@alch/ui/icons/24";
import { PlanSelection } from "@features/signup/Pages/PlanPage";
import { TIER_CONFIG } from "@util/payment/tierConfig";
import clsx from "clsx";

interface PlanSelectionCardProps {
  plan: PlanSelection;
  highlight?: boolean;
  onSelect: (tier: TeamPlanTier) => void;
}

const PlanSelectionCard = ({
  plan: { subtitle, tier, monthlyPrice, features, poweringTeamsLogos },
  highlight,
  onSelect,
}: PlanSelectionCardProps) => {
  return (
    <div
      className={clsx(
        "flex flex-col rounded-lg border border-primary",
        highlight ? "bg-primary" : "bg-subtle",
      )}
    >
      <div className="border-b border-primary p-6 pb-4">
        <h3 className="text-heading-h2 text-primary">
          {TIER_CONFIG[tier].name}
        </h3>

        <div className="mt-2 truncate text-paragraph-200-regular text-secondary">
          {subtitle}
        </div>

        <div
          className={clsx(
            "mt-4 text-heading-display-medium",
            "[&>small]:text-heading-h4 [&>small]:text-secondary",
            highlight ? "text-brand" : "text-primary",
          )}
        >
          {monthlyPrice}
        </div>
      </div>

      <div className="mb-auto p-6 pt-4">
        <h4 className="text-paragraph-300-medium text-brand">
          {features.title}
        </h4>

        <ul>
          {features.items.map((feature) => (
            <li
              key={feature}
              className="mt-4 flex gap-3 text-paragraph-300-regular text-primary"
            >
              <CheckContained className="my-1" />

              {feature}
            </li>
          ))}
        </ul>
      </div>

      <Button
        intent={highlight ? "primary" : "secondary"}
        size="lg"
        className={clsx("m-6", highlight ? "" : "bg-contrast-darkest")}
        onClick={() => onSelect(tier)}
        trailingIcon={<ChevronRight />}
      >
        Select Plan
      </Button>

      <div className="p-6 pt-4">
        <div className="text-center text-paragraph-100-regular text-gray-500">
          Powering teams like
        </div>

        <div className="mt-2.5 flex justify-center">
          {poweringTeamsLogos.map((logoUrl, index) => (
            <img
              key={index}
              src={logoUrl}
              alt=""
              style={{ width: 90, height: 28 }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanSelectionCard;
