import useSearchParam, { SearchParam } from "@hooks/useSearchParam";
import { Path } from "@util/paths";
import safeRedirect from "@util/safeRedirect";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * When the user has completed signup, handle redirectUrl params
 */
const useSignupCompleteRedirect = (signupComplete: boolean | undefined) => {
  const history = useHistory();
  const location = useLocation();
  const redirectUrl = useSearchParam(SearchParam.RedirectUrl);
  const [hasRedirected, setHasRedirected] = useState(false);

  useEffect(() => {
    if (!signupComplete || hasRedirected) return;
    /**
     * Below should run a single time when the user is first detected to have completed
     * signup - either by having already done so and loading the page, or by finishing
     * the signup flow.
     */

    const defaultOrigin = window.location.origin;
    const parsedRedirectUrl = new URL(redirectUrl ?? "", defaultOrigin);

    // Absolute redirect url
    if (parsedRedirectUrl.origin !== defaultOrigin) {
      setHasRedirected(true);
      return void safeRedirect(parsedRedirectUrl.toString());
    }

    // An explicit redirect path is set
    if (
      parsedRedirectUrl.pathname !== "/" &&
      !parsedRedirectUrl.pathname.startsWith(Path.Signup)
    ) {
      setHasRedirected(true);
      return history.replace({
        pathname: parsedRedirectUrl.pathname,
        search: parsedRedirectUrl.search,
      });
    }

    // Remove the invalid/missing redirect url param
    // The main app will be shown automatically.
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(SearchParam.RedirectUrl);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setHasRedirected(true);
  }, [signupComplete, redirectUrl, history, location, hasRedirected]);
};

export default useSignupCompleteRedirect;
