import { SearchParam } from "@hooks/useSearchParam";
import { Path } from "@util/paths";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SignupStepPathMap, SignupStepsList } from "../constants";

/**
 * Redirect rules when first loading the signup flow
 */
const useSignupDefaultLocation = () => {
  const location = useLocation();

  return useMemo(() => {
    const { search, pathname } = location;
    const searchParams = new URLSearchParams(search);
    const hasRedirectParam = searchParams.has(SearchParam.RedirectUrl);

    // If an explicit redirect url is not set, set it to the path the user was
    // trying to access when loading the page so that they are redirected back
    // to that page after completing signup
    if (!hasRedirectParam && !pathname.startsWith(Path.Signup)) {
      // Remove test signup param from redirect url
      const redirectSearchParams = new URLSearchParams(searchParams);
      redirectSearchParams.delete(SearchParam.TestSignup);

      const redirectSearch =
        redirectSearchParams.size === 0
          ? ""
          : `?${redirectSearchParams.toString()}`;

      // Set redirect url to the current path
      searchParams.set(SearchParam.RedirectUrl, `${pathname}${redirectSearch}`);
    }

    // On first page load always redirect to the first step
    return {
      ...location,
      search: searchParams.toString(),
      pathname: SignupStepPathMap[SignupStepsList[0]],
    };
  }, [location]);
};

export default useSignupDefaultLocation;
