import { ProjectType } from "@alch/dx-entities";
import {
  BarGroup03,
  CoinsRotate,
  Gaming,
  Image02,
  Marketplace,
  Server01,
  ShieldPlus,
  Social,
  UserProfile01,
  Wallet01,
} from "@alch/ui/icons/16";
import { projectTypeLabels } from "@features/home/recommended-product/ProductRecommendationBanner";
import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import { useSignupForm } from "@features/signup/hooks/useSignupForm";
import PillRadioGroup, {
  PillRadioItem,
} from "@ui/pill-radio-group/PillRadioGroup";
import TextField from "@ui/text-field/TextField";

const CURRENT_STEP = SignupStep.Project;

const PROJECT_TYPE_ITEMS: PillRadioItem<ProjectType>[] = [
  {
    value: ProjectType.Analytics,
    Icon: BarGroup03,
  },
  {
    value: ProjectType.Defi,
    Icon: CoinsRotate,
  },
  {
    value: ProjectType.Gaming,
    Icon: Gaming,
  },
  {
    value: ProjectType.Wallet,
    Icon: Wallet01,
  },
  {
    value: ProjectType.Security,
    Icon: ShieldPlus,
  },
  {
    value: ProjectType.Social,
    Icon: Social,
  },
  {
    value: ProjectType.Marketplace,
    Icon: Marketplace,
  },
  {
    value: ProjectType.InfraAndTooling,
    Icon: Server01,
  },
  {
    value: ProjectType.NFTs,
    Icon: Image02,
  },
  {
    value: ProjectType.Identity,
    Icon: UserProfile01,
  },
].map((option) => ({ ...option, label: projectTypeLabels[option.value] }));

const ProjectPage = () => {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { isValid },
  } = useSignupForm({
    schemaField: SignupSchemaField.Project,
    defaultValues: {
      [SignupFormFieldName.ProjectType]: "",
      [SignupFormFieldName.OtherProjectType]: "",
    },
  });

  return (
    <SignupTemplatePage
      title="What are you building?"
      subtitle="We'll use this to connect you with the best solutions for your use case."
      currentStep={CURRENT_STEP}
      disableNextButton={!isValid}
      onNextButtonClick={handleSubmit}
    >
      <PillRadioGroup
        items={PROJECT_TYPE_ITEMS}
        control={control}
        name={SignupFormFieldName.ProjectType}
        onChange={() =>
          resetField(SignupFormFieldName.OtherProjectType, { defaultValue: "" })
        }
      />
      <div className="h-4" />
      <TextField
        control={control}
        label="Other"
        name={SignupFormFieldName.OtherProjectType}
        onFocus={() => {
          resetField(SignupFormFieldName.ProjectType, {
            defaultValue: "" as ProjectType,
          });
        }}
      />
    </SignupTemplatePage>
  );
};

export default ProjectPage;
