import { EventPrefix, analyticsEvent } from "@util/analytics";
import {
  getStoredAuthToken,
  logOutFromAuthchemy,
  redirectToAuthchemy,
  setStoredAuthToken,
} from "@util/auth";
import { trpc } from "@util/trpc/trpcClient";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSearchParam, { SearchParam } from "./useSearchParam";

/**
 * Sets an inbound auth token, verifies it, and redirects to authchemy if the token is invalid.
 */
const useAuthRedirect = () => {
  const { search } = useLocation();
  const history = useHistory();
  const inboundAuthToken = useSearchParam(SearchParam.AuthToken);
  const redirectAttemptParam = useSearchParam(SearchParam.RedirectAttempt);
  const [authTokenSet, setAuthTokenSet] = useState<boolean>(false);

  // Track redirect attempts to prevent infinite redirects
  const redirectAttempt = useMemo(
    () => Math.max(+(redirectAttemptParam || "") || 0, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- want to only capture initial value on page load
    [],
  );

  // Allow the auth token from an inbound ?authToken=... query string parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    // If authchemy passed us an authToken then store the token, and reload the same URL without the authToken query string parameter
    if (inboundAuthToken) {
      setStoredAuthToken(inboundAuthToken);
      searchParams.delete(SearchParam.AuthToken);
      searchParams.delete(SearchParam.RedirectAttempt);
      history.replace({ ...history.location, search: searchParams.toString() });

      analyticsEvent(`${EventPrefix.Auth}: Signed in to dashboard`);
    }

    const authToken = getStoredAuthToken();
    // If no stored authToken, skip the status check and redirect the user to Authchemy to login
    if (!authToken) {
      redirectToAuthchemy();
      return;
    }

    setAuthTokenSet(true);
  }, [history, inboundAuthToken, search]);

  const userRes = trpc.users.getUser.useQuery(undefined, {
    // Wait for the auth token to be set or overwritten by the inbound authToken query string parameter
    enabled: authTokenSet,
  });

  const unauthorized = userRes.error?.data?.code === "UNAUTHORIZED";

  useEffect(() => {
    if (unauthorized) {
      if (redirectAttempt < 2) {
        // The auth token was invalid, redirect to get a new one from authchemy
        redirectToAuthchemy(redirectAttempt + 1);
        return;
      }

      // Authchemy keeps redirecting back to dashboard with invalid authToken. Force user logout.
      logOutFromAuthchemy();
      return;
    }
  }, [userRes.error, unauthorized, redirectAttempt]);

  return {
    // Consider us loading even if there is an error since we will be immediately redirecting
    loading: !userRes.data,
    // Don't flash an auth error right before redirecting
    error: !unauthorized ? userRes.error : undefined,
    refetch: userRes.refetch,
  };
};

export default useAuthRedirect;
