import { Flag } from "@alch/dx-entities";
import { Button, IconButton } from "@alch/ui";
import { PhoneCall01 as PhoneCall01Md } from "@alch/ui/icons/20";
import { PhoneCall01 as PhoneCall01Lg, X03 } from "@alch/ui/icons/24";
import { useFlag } from "@hooks/useFlag";
import { analyticsEvent, EventPrefix } from "@util/analytics";
import { motion } from "framer-motion";
import { Popover } from "radix-ui";
import { useState } from "react";

interface ScheduleCallProps {
  hidden: boolean;
}

const ScheduleCall = ({ hidden }: ScheduleCallProps) => {
  const showScheduleCall = useShowScheduleCall();
  const scheduleCallFlag = useFlag(Flag.ScheduleCallPopover);
  const [open, setOpen] = useState(false);

  if (!showScheduleCall || hidden || scheduleCallFlag.isPending) return null;

  return (
    <Popover.Root onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          as={motion.button}
          size="lg"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 3, ease: "easeIn" }}
          className="fixed right-5 bottom-20 z-50 h-11 rounded-full"
          icon={open ? <X03 /> : <PhoneCall01Md />}
          onClick={() => {
            analyticsEvent(
              `${EventPrefix.ScheduleCallPopover}: Clicked open popover`,
              {
                openPopoverText: scheduleCallFlag.data?.openPopoverText,
              },
            );
          }}
        >
          {open ? "Close" : scheduleCallFlag.data?.openPopoverText}
        </Button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content asChild side="top" align="end" sideOffset={8}>
          <motion.div
            initial={{ scale: 0.5, opacity: 0, y: 24 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            transition={{ duration: 0.3, opacity: { duration: 0.2 } }}
            className="relative z-40 flex w-72 origin-bottom-right flex-col items-start gap-4 rounded-lg border border-primary bg-primary p-6 shadow-gray-lg"
          >
            <Popover.Close asChild>
              <IconButton className="absolute top-2 right-2 text-icon-secondary">
                <X03 />
              </IconButton>
            </Popover.Close>

            <PhoneCall01Lg className="size-8 text-icon-primary" />

            <div className="text-heading-h4 text-primary">
              {scheduleCallFlag.data?.description}
            </div>

            <Popover.Close asChild>
              <Button
                size="sm"
                as="a"
                target="_blank"
                href={scheduleCallFlag.data?.ctaUrl}
                onClick={() => {
                  analyticsEvent(
                    `${EventPrefix.ScheduleCallPopover}: Clicked popover CTA`,
                    {
                      ctaText: scheduleCallFlag.data?.ctaText,
                      ctaUrl: scheduleCallFlag.data?.ctaUrl,
                      description: scheduleCallFlag.data?.description,
                    },
                  );
                }}
              >
                {scheduleCallFlag.data?.ctaText}
              </Button>
            </Popover.Close>
          </motion.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ScheduleCall;

export const useShowScheduleCall = () => {
  const scheduleCallFlag = useFlag(Flag.ScheduleCallPopover);

  return scheduleCallFlag.data?.enabled ?? false;
};
