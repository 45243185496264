import { useLocation } from "react-router-dom";

/**
 * Global search params
 **/
export enum SearchParam {
  RedirectUrl = "redirectUrl",
  AuthToken = "authToken",
  ShowUpgradeModal = "upgrade",
  TestSignup = "test_signup",
  Billing2 = "billing_2",
  // During checkout, tell the server to fail the CC fingerprint check
  TestFailFingerprint = "test_fail_fingerprint",
  RedirectAttempt = "redirect_attempt",
  TestCheckoutSuccess = "test_checkout_success",
  TestPlan = "test_plan",
  TestTerm = "test_term",
  TestUsageCapType = "test_usage_cap_type",
  TestSignupProjectType = "test_signup_project_type",
  TestRecommendedProduct = "test_recommended_product",
  TestUpgradeOnboarding = "test_upgrade_onboarding",
  TestUseNetworkError = "test_use_network_error",
  TestGettingStarted = "test_getting_started",
  ShowModal = "show_modal",
  ViewAsTeam = "view_as_team",
  ViewAsUser = "view_as_user",

  // For webhooks
  InitialWebhookType = "initial-webhook-type",
  InitialWebhookNetwork = "initial-webhook-network",
  InitialWebhookChain = "initial-webhook-chain",
}

const useSearchParam = (searchParam: SearchParam | string) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get(searchParam);
};

export default useSearchParam;
