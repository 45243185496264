import { TeamPlanTier } from "@alch/dx-entities";
import { InlineAlert } from "@alch/ui";
import { formatWholeAndFractionalDollars } from "@features/payment/Checkout/lib/numberFormatters";
import SelectedTierLabel from "@features/signup/Pages/PlanPage/SelectedTierLabel";
import { TIER_CONFIG } from "@util/payment/tierConfig";
import clsx from "clsx";

export const priceTextClassName = clsx(
  "text-paragraph-200-medium text-grayscale-950 [&>small]:text-[length:inherit]",
);

interface PaymentSummaryRowProps {
  label: string;
  children: React.ReactNode;
}

const PaymentSummaryRow = ({ label, children }: PaymentSummaryRowProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex">
        <p className="text-paragraph-200-regular text-grayscale-700">{label}</p>
      </div>
      {children}
    </div>
  );
};

interface PaymentSummaryCardProps {
  tier: TeamPlanTier;
}

const PaymentSummaryCard = ({ tier }: PaymentSummaryCardProps) => {
  const pricing = TIER_CONFIG[tier];

  return (
    <div className="grid grid-cols-1 gap-y-1 rounded-2xl border border-grayscale-200 bg-white p-6">
      <PaymentSummaryRow label="Selected tier">
        <SelectedTierLabel tier={tier} />
      </PaymentSummaryRow>

      <PaymentSummaryRow
        label={`Due today${pricing.preAuthCharge ? " - Reimbursed after 7 days" : ""}`}
      >
        <div className="flex">
          <p className={priceTextClassName}>
            {formatWholeAndFractionalDollars(
              pricing.preAuthCharge || pricing.baseCost,
            )}
          </p>
        </div>
      </PaymentSummaryRow>

      {[TeamPlanTier.FREE, TeamPlanTier.ENTERPRISE].includes(tier) && (
        <InlineAlert intent="info" className="mt-2">
          {tier === TeamPlanTier.FREE &&
            `We won't charge you unless you upgrade to a paid tier.`}
          {tier === TeamPlanTier.ENTERPRISE &&
            `Our team will contact you to discuss a custom plan.`}
        </InlineAlert>
      )}
    </div>
  );
};

export default PaymentSummaryCard;
