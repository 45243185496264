import useSearchParam from "@hooks/useSearchParam";
import { callEndpoint, Method } from "@http/axiosConfig";
import { useQuery } from "@tanstack/react-query";

/**
 * Determine whether we should show stripe to the user instead of chargify.  It can
 * be used in signup or the main app.
 *
 * Override with ?flag_stripe=true
 */
export default function useStripeEnabled() {
  const { override, value: overrideValue } = useStripeOverrideFlag();

  // This query asks the backend if we *have* to use stripe because the user doesn't exist in Chargify.
  // It allows teamless so it can be used in the signup flow.
  return useQuery({
    queryKey: ["should-use-stripe"],
    queryFn: async () => {
      const backendShouldUseStripe = await callEndpoint<{
        should_use_stripe: boolean;
      }>(Method.GET, `/api/should-use-stripe`);
      return override
        ? overrideValue
        : backendShouldUseStripe.should_use_stripe;
    },
  });
}

function useStripeOverrideFlag():
  | { override: true; value: boolean }
  | { override: false; value: undefined } {
  const flagValue = useSearchParam("flag_stripe");

  if (flagValue === "true") {
    return { override: true, value: true };
  }
  if (flagValue === "false") {
    return { override: true, value: false };
  }
  return { override: false, value: undefined };
}
