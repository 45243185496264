import { TeamPlanTier } from "@alch/dx-entities";
import { Link } from "@alch/ui";
import {
  formatCompactNumber,
  formatWholeDollars,
} from "@features/payment/Checkout/lib/numberFormatters";
import PlanSelectionCard from "@features/signup/Pages/PlanPage/PlanSelectionCard";
import SignupTemplatePage from "@features/signup/Pages/SignupTemplatePage";
import { useSignupFlowFormData } from "@features/signup/SignupFlowProvider";
import {
  SignupFormFieldName,
  SignupSchemaField,
  SignupStep,
} from "@features/signup/constants";
import useNavigateSignup from "@features/signup/hooks/useNavigateSignup";
import DappRadar from "@static/signup/powering-teams-logos/dapp-radar.svg";
import Earnifi from "@static/signup/powering-teams-logos/earnifi.svg";
import InkFinance from "@static/signup/powering-teams-logos/ink-finance.svg";
import Lido from "@static/signup/powering-teams-logos/lido.svg";
import OpenSea from "@static/signup/powering-teams-logos/open-sea.svg";
import VoxelX from "@static/signup/powering-teams-logos/voxel-x.svg";
import { useEffectOnUpdate } from "@util/hooks";
import { ENTERPRISE_TIERS, TIER_CONFIG } from "@util/payment/tierConfig";
import { Url } from "@util/urls";
import { omit } from "lodash";

const CURRENT_STEP = SignupStep.Plan;

export interface PlanSelection {
  tier: TeamPlanTier;
  subtitle: string;
  monthlyPrice: React.ReactNode;
  features: { title: string; items: string[] };
  poweringTeamsLogos: string[];
}

export const usePlans = (): PlanSelection[] => {
  return [
    {
      tier: TeamPlanTier.FREE,
      subtitle: "Web3’s most powerful free tier.",
      monthlyPrice: (
        <>
          {formatWholeDollars(TIER_CONFIG[TeamPlanTier.FREE].baseCost)}
          <small>/mo</small>
        </>
      ),
      features: {
        title: "Included in Free",
        items: [
          `${formatCompactNumber(TIER_CONFIG[TeamPlanTier.FREE].includedUnits)} CU per month`,
          `Max ${TIER_CONFIG[TeamPlanTier.FREE].numOfApps} apps & ${TIER_CONFIG[TeamPlanTier.FREE].numOfActiveWebhooks} webhooks`,
          "30 requests per second",
          "Discord support",
        ],
      },
      poweringTeamsLogos: [InkFinance, VoxelX],
    },
    {
      tier: TeamPlanTier.PAYG,
      subtitle: "Web3’s most affordable plan to grow.",
      monthlyPrice: (
        <>
          <small>From </small>
          {formatWholeDollars(1)}
          <small>/mo</small>
        </>
      ),
      features: {
        title: "Use more, pay less",
        items: [
          `Max ${TIER_CONFIG[TeamPlanTier.PAYG].numOfApps} apps & ${TIER_CONFIG[TeamPlanTier.PAYG].numOfActiveWebhooks} webhooks`,
          "300 requests per second",
          "Trace & Debug API Included",
          "Priority support",
        ],
      },
      poweringTeamsLogos: [DappRadar, Earnifi],
    },
    {
      tier: TeamPlanTier.ENTERPRISE,
      subtitle: "VIP service & dedicated engineering support.",
      monthlyPrice: "Custom",
      features: {
        title: "Make it your own",
        items: [
          "Unlimited apps & webhooks",
          "Unlimited throughput",
          "Volume discounts & SLAs",
          "24/7 VIP engineering support",
        ],
      },
      poweringTeamsLogos: [Lido, OpenSea],
    },
  ];
};

const PlanPage = () => {
  const { formData, setFormData } = useSignupFlowFormData();
  const { goToNextStep } = useNavigateSignup({ currentStep: CURRENT_STEP });
  const plans = usePlans();

  useEffectOnUpdate(() => {
    goToNextStep();
  }, [formData[SignupSchemaField.Plan]]);

  const subtitle = (
    <div>
      Not sure which plan is best for you?{" "}
      <Link href={Url.AlchemyPricing} target="_blank">
        Compare Plans
      </Link>
    </div>
  );

  return (
    <SignupTemplatePage
      title="Select your plan"
      subtitle={subtitle}
      currentStep={CURRENT_STEP}
      hideNextButton
    >
      <div className="grid w-full max-w-[1164px] auto-cols-fr grid-cols-1 gap-7.5 lg:grid-flow-col">
        {plans.map((plan, index) => (
          <PlanSelectionCard
            key={plan.tier}
            plan={plan}
            highlight={index === 1}
            onSelect={(tier) => {
              setFormData((existingFormData) => {
                return {
                  // Remove enterprise details if user selects a different plan
                  ...(ENTERPRISE_TIERS.includes(tier)
                    ? existingFormData
                    : omit(existingFormData, [
                        SignupSchemaField.EnterpriseDetails,
                      ])),
                  [SignupSchemaField.Plan]: {
                    [SignupFormFieldName.PlanType]: tier,
                  },
                };
              });
            }}
          />
        ))}
      </div>
    </SignupTemplatePage>
  );
};

export default PlanPage;
