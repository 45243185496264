import useActivePlan from "@features/profile/PlanPage/hooks/useActivePlan";
import useCurrentUser from "@queries/useCurrentUser";
import useTeam from "@queries/useTeam";
import { initAnalytics, setAnalyticsUser } from "@util/analytics";
import { trackPageVisit } from "@util/analytics/brevo.ts";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

initAnalytics();

/**
 * Use this hook at a high level component.
 */
export default function useAnalyticsInDash() {
  const { data: team } = useTeam();
  const { data: user } = useCurrentUser();
  const { data: activePlan } = useActivePlan();

  const history = useHistory();

  useEffect(() => {
    if (user && activePlan && team) {
      setAnalyticsUser({
        referredBy: team?.referred_by,
        referredByAffiliate: team?.referred_by_affiliate,
        tier: activePlan?.tier,
        teamName: team?.name,
        setupStage: team?.setup_stage,
        extId: user.extId,
        internalId: user.id,
        teamId: user.teamId,
        email: user.email,
        isStaff: user.isStaff,
        createdAt: user.createdAt,
        isBillingAdmin: user.isBillingAdmin,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      });
    }
  }, [team, user, activePlan]);

  useEffect(() => {
    // Track the initial page visit
    trackPageVisit(location.pathname);

    history.listen((location, action) => {
      if (action === "PUSH") {
        trackPageVisit(location.pathname);
      }
    });
  }, [history]);
}
